import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG, generarNumeroRandom } from '../../../../../rbts/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';

type Props = {
  show: boolean;
  dataEdit: any;
  title: string;
  handleClose: (updateGrid: boolean) => void;
  handleEdit: (data: boolean) => void;
};

const dataAcademicSchema = Yup.object().shape({
  institucion: Yup.string().required('Requerido.'),
  carrera: Yup.string().required('Requerido.'),
  estudio_actual: Yup.boolean(),
  fecha_inicio: Yup.date(),
  fecha_fin: Yup.date().when('estudio_actual', {
    is: false,
    then: Yup.date().required('Requerido.'),
    otherwise: Yup.date(),
  }), 
});

const AcademicModal: React.FC<Props> = ({
  show,
  dataEdit,
  title,
  handleClose,
  handleEdit,
}) => {
  const [loading, setLoading] = useState(false);

  const AcademicForm = useFormik({
    initialValues: dataEdit,
    validationSchema: dataAcademicSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        if (!values.id) {
          values.es_actual = true;
        }
       values.id = values.id === 0 ? generarNumeroRandom() : values.id;
        handleEdit(values);
        AcademicForm.resetForm();
        setLoading(false);
      }, 1000);
    },
  });

  const handleOnChangeWorking = (e: React.ChangeEvent<HTMLInputElement>) => {
    const radioValue = Number(e.target.value);
    AcademicForm.setFieldValue(
      'estudio_actual',
      radioValue === 1 ? true : false
    );
    AcademicForm.setFieldTouched('fecha_fin', false, false);
  };
  
  return (
    <Modal
      id="kt_modal_work"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-xl"
      show={show}
      onHide={() => {
        AcademicForm.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header " id="kt_modal_work_header">
          <h2> {title} </h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              AcademicForm.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5">
          <form
            id="kt_modal_detail_form"
            className="form"
            action="#"
            onSubmit={AcademicForm.handleSubmit}
          >
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="w-100 pe-5 mb-10">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto">
                      ¿En curso?
                    </label>
                  </div>
                  <div className="w-100 d-flex mt-3">
                    <label className="form-check-label fw-bolder text-gray-800 w-50">
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        value={1}
                        onChange={handleOnChangeWorking}
                        checked={
                          AcademicForm.values.estudio_actual === true
                        }
                        name="estudio_actual"
                      />
                      Si
                    </label>
                    <label className="form-check-label fw-bolder text-gray-800 w-50">
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        checked={
                          AcademicForm.values.estudio_actual === false
                        }
                        value={2}
                        onChange={handleOnChangeWorking}
                        name="estudio_actual"
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto required">
                      Institución
                    </label>
                    {AcademicForm.touched.institucion &&
                      AcademicForm.errors.institucion && (
                        <div className="fv-plugins-message-container text-danger w-100 ms-5">
                          <span role="alert">
                            {AcademicForm.errors.institucion}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    className="form-control mb-3"
                    type="text"
                    placeholder="Institución"
                    {...AcademicForm.getFieldProps('institucion')}
                  ></input>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto required">
                      Carrera
                    </label>
                    {AcademicForm.touched.carrera &&
                      AcademicForm.errors.carrera && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">
                            {AcademicForm.errors.carrera}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    className="form-control mb-3"
                    type="text"
                    placeholder="Carrera"
                    {...AcademicForm.getFieldProps('carrera')}
                  ></input>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto required">
                      Fecha inicio
                    </label>
                    {AcademicForm.touched.fecha_inicio &&
                      AcademicForm.errors.fecha_inicio && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">
                            {AcademicForm.errors.fecha_inicio}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    type="date"
                    className="form-control"
                    // onKeyDown={(e) => e.preventDefault()}
                    value={
                      new Date(AcademicForm.values.fecha_inicio)
                        .toISOString()
                        .split('T')[0]
                    }
                    onChange={(e) => {
                      if (e.target.value) {
                        const fecha = new Date(e.target.value)
                          .toISOString()
                          .split('T')[0];
                        AcademicForm.setFieldValue('fecha_inicio', fecha);
                      }
                    }}
                  ></input>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className={"fw-bold fs-6 mb-2 w-auto" + (!AcademicForm.values.estudio_actual ? " required" : "")}>
                      Fecha fin
                    </label>
                    {AcademicForm.touched.fecha_fin &&
                      AcademicForm.errors.fecha_fin && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">
                            {AcademicForm.errors.fecha_fin}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    type="date"
                    className="form-control"
                    disabled={AcademicForm.values.estudio_actual}
                    // onKeyDown={(e) => e.preventDefault()}
                    value={
                      new Date(AcademicForm.values.fecha_fin)
                        .toISOString()
                        .split('T')[0]
                    }
                    onChange={(e) => {
                      if (e.target.value) {
                        const fecha = new Date(e.target.value)
                          .toISOString()
                          .split('T')[0];
                        AcademicForm.setFieldValue('fecha_fin', fecha);
                      }
                    }}
                  ></input>
                </div>
              </div>
            </div>
          </form>

          <div className="text-center pt-8">
            <button
              type="reset"
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => {
                AcademicForm.resetForm();
                handleClose(false);
              }}
            >
              {title === 'Detalle Formación Académica' ? 'Cerrar' : 'Cancelar'}
            </button>

           
              <button
                type="button"
                onClick={() => {
                  AcademicForm.submitForm();
                }}
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">{title === 'Detalle Experiencia Profesional'
                ? 'Editar'
                : 'Guardar'}</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
          
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { AcademicModal };
