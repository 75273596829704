import React, { FC } from 'react';
import { PageTitle, PageLink } from '../../../rbts/layout/core';
import { View } from './components/View';

const breadCrumbs: Array<PageLink> = [];

const CandidatesDocumentsPage: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={breadCrumbs}>Documentos CV </PageTitle>
            <View />
        </>
    );
};

export { CandidatesDocumentsPage };
