import React, { useState, useEffect } from 'react';
import { Filter } from './Filter';
import { List } from './List';
import { useGetAllCVCandidates } from '../hooks/CVCandidatesHook';

const View: React.FC = () => {
  const [loadingView, setloadingView] = useState(true);
  const pageSize = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const [reset, setReset] = useState(Math.random() * 40);
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues, setInitFilterValues] = useState({
    perfil: '',
    herramientas: '',
    edad_minima: '',
    edad_maxima: '',
    actividades: '',
  });
  const { data, count, loadingRequest } = useGetAllCVCandidates(
    reloadGrid,
    pageSize,
    currentPage,
    initFilterValues
  );

  const totalPages = Math.ceil(count / pageSize)
    ? Math.ceil(count / pageSize)
    : 1;

  const onSearchFilter = (values: any) => {
    setloadingView(true);
    if (values) {
      setInitFilterValues(values);
    } else {
      setInitFilterValues({
        perfil: '',
        herramientas: '',
        edad_minima: '',
        edad_maxima: '',
        actividades: '',
      });
    }
    setReloadGrid(Math.random() * 40);
    setReset(Math.random() * 40);
    setCurrentPage(1);
  };
  
  const onCleanFilter = () => {
    setloadingView(true);
    setInitFilterValues({
      perfil: '',
      herramientas: '',
      edad_minima: '',
      edad_maxima: '',
      actividades: '',
    });
    setReloadGrid(Math.random() * 40);
    setReset(Math.random() * 40);
    setCurrentPage(1);
  };
  const onRefreshFilter = () => {
    setloadingView(true);
    setReloadGrid(Math.random() * 40);
    // setReset(Math.random() * 40);
    setCurrentPage(1);
  };
  

  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
    }
  }, [loadingRequest]);

  return (
    <>
      <div className="row gy-5 g-xl-12">
        <Filter
          initFilterValues={initFilterValues}
          onCleanFilter={onCleanFilter}
          onSearchFilter={onSearchFilter}
          onResetFilter={onRefreshFilter}
        />
      </div>
      <div className="row g-6 g-xl-9">
        <List
          data={data}
          loadingGrid={loadingView}
          count={count}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(pageNumber: number) => {
            setloadingView(true);
            setCurrentPage(pageNumber);
          }}
          reset={reset}
        />
      </div>
    </>
  );
};

export { View };
