import React, { useState, useEffect } from 'react';
import { useGetDocumentsCandidates } from '../hooks/DocumentsCandidatesHook';
import { List } from './List';


const View: React.FC = () => {
    const [loadingView, setloadingView] = useState(true);
    const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
    const { count, data, loadingRequest } = useGetDocumentsCandidates(reloadGrid);

    useEffect(() => {
        if (loadingRequest > 0) {
            setloadingView(false);
        }
    }, [loadingRequest]);

    const onUploadFile = () => {
        setloadingView(true);
        setReloadGrid(Math.random() * 40)
    }
    return (
        <>
            <div className='row g-6 g-xl-9'>
                <List count={count} data={data} loadingGrid={loadingView} onUploadFile={onUploadFile}></List>
            </div >
        </>
    );
};

export { View };
