import { useState, useEffect } from 'react';
import { candidateGetPerID, getDocumentsByCV } from '../../../../service/candidates/Candidates';
import { CandidateModel } from '../../candidates_add/models/CandidateModel';
import { DocumentCandidateModel } from '../../../candidates_doc/models/CandidatesDocuments';
import { useHistory } from 'react-router-dom';


export const useGetOne = ( id: number ) => {

  const initModel = {
    id: 0,
    edad: 18,
    fecha_nacimiento: new Date().toISOString().split('T')[0],
    religion: {
      id: 0,
      name: '',
      code: '',
    },
    lugar_nacimiento: '',
    domicilio: '',
    telefono_particular: '',
    telefono_particular_format: '',
    movil: '',
    movil_format: '',
    toma_medicamentos_controlados: 2,
    medicamentos: '',
    perfil: '',
    sueldo_actual: '',
    expectativa_cambio: '',
    tiene_credito_infonavit: false,
    colonia: '',
    municipio: '',
    estado: '',
    codigo_postal: '',
    modalidad: {
      id: 0,
      name: '',
      code: '',
    },
    estadoCivil: {
      id: 0,
      name: '',
      code: '',
    },
    genero: {
      id: 0,
      name: '',
      code: '',
    },
    habilidades_tecnicas: '',
    experienciaProfesional: [],
    formacionAcademica: [],
    idiomas: [],
    desarrolloProfresional: [],
    vacantesInteres: [],
    documentos: [],
  };
  const [loadingRequest, setLoadingRequest] = useState(0);
  const [initValues, setInitValues] = useState<CandidateModel>(initModel);
  const history = useHistory();
  useEffect(() => {
    
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await candidateGetPerID(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setLoadingRequest(1);
        setInitValues(res);
      }else{
        setLoadingRequest(1);
        setInitValues(initModel);
      }
    };
    if(id){
      fetchPost();

    }else{
      setInitValues(initModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return { initValues, loadingRequest };
};

export const useGetDocumentsCV = (id:number) => {

  const [data, setData] = useState<DocumentCandidateModel[]>([]);
  const [loadingDocuments, setLoadingDocuments] = useState(0);
  const history = useHistory()

  useEffect(() => {
      const fetchPost = async () => {
        setLoadingDocuments(0)
          const res = await getDocumentsByCV(id).then().catch((error) => {
              history.push('/error/500')
          });
          if (res) {
              setData(res);
              setLoadingDocuments(1);
          }
      };
      if (id) {
          fetchPost();
      }
  }, [id]);
  return { data, loadingDocuments }
}