import React, { useState, useEffect, useCallback, useRef, RefObject } from 'react';
import { MenuComponent } from '../../../../rbts/assets/ts/components';
import { Preload } from '../../../../rbts/helpers';
import { uploadFiledCV } from '../../../service/candidates/Candidates';
import { toast } from 'react-toastify';
import axios from 'axios';

type Props = {
    data: any;
    loadingGrid: boolean;
    count: number;
    onUploadFile: () => void;
};

const List: React.FC<Props> = ({
    data,
    loadingGrid,
    count,
    onUploadFile
}) => {

    const fileRefs = useRef<HTMLInputElement[]>([]);
    const _onOpenFile = (index: number, event: any) => {
        event.preventDefault();
        event.stopPropagation();
        fileRefs?.current[index]?.click();
    };
    const [uploadLoading, setUploadLoading] = useState<boolean>(false);
    const [validate, setValidate] = useState<string>('');

    const uploadFile = async (
        file: any, code: any,
    ) => {

        setUploadLoading(true);
        const _file = file[0];
        uploadFiledCV(_file, code).then((res) => {
            setUploadLoading(false);
            toast.success(`${res.data.message}`, {
                position: 'top-right',
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            onUploadFile();
        })
            .catch((err) => {
                let resMessageToast: string = '';
                const {
                    data: { message },
                } = err.response;
                resMessageToast = `${message}`;
                setUploadLoading(false);
                toast.error(resMessageToast, {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            });
    }

    const downloadFile = (fileName: string, event: any) => {
        event.preventDefault();
        event.stopPropagation();

        axios({
            url: `${process.env.REACT_APP_API}candidatos/documentos/download/`,
            method: 'POST',
            data: {
                nombreArchivo: fileName,
            },
            responseType: 'blob',
        })
            .then((response) => {
                const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = urlBlob;
                link.setAttribute('download', fileName);
                link.click();
                window.URL.revokeObjectURL(urlBlob);
            })
            .catch(async (error) => {
                let responseObj = JSON.parse(await error.response.data.text());
            });
    };

    setTimeout(() => {
        MenuComponent.reinitialization();
    }, 1000);

    return (
        <>
            {
                loadingGrid ? <Preload /> : count <= 0 ?
                    <div className="d-flex align-items-center">
                        <div className="text-muted text-center fs-6 w-100 m-5">
                            Sin datos
                        </div>
                    </div> :
                    data.map((item: any, index: any) => {
                        return <div className="col-md-6 col-xl-4" key={item.codigo}>
                            <a href="#" className="card border-hover-primary ">
                                <div className="card-header border-0 pt-9">
                                    <div className="card-title m-0">
                                        <div className="symbol symbol-50px w-50px bg-light">
                                            <img src="/media/svg/files/generic.svg" title={`${item.cargado ? 'Descargar archivo' : ''}`} onClick={(event) => {

                                                if (item.cargado) {
                                                    downloadFile(item.nombre_archivo, event);
                                                }

                                            }} />
                                        </div>
                                    </div>
                                    <div className="card-toolbar">
                                        <span className={`badge ${item.cargado ? 'badge-light-success' : 'badge-light-warning'} fw-bold me-auto px-4 py-3`}>{item.nombre_archivo != null ? 'Completado' : 'Pendiente'}</span>
                                    </div>
                                </div>
                                <div className="card-body p-9">
                                    <div className={`fs-3 fw-bold text-gray-900 text-truncate ${item.es_requerido ? 'required' : ''}`} title={item.nombre}>{item.nombre}</div>
                                    <p className="text-gray-500 fw-semibold fs-8 mt-1 mb-7 text-truncate text-hover-primary" onClick={(event) => {
                                        if (item.cargado) {
                                            downloadFile(item.nombre_archivo, event);
                                        }
                                    }} title={item.nombre_archivo != null ? item.nombre_archivo : 'No se ha cargado ningún archivo'}>{item.nombre_archivo != null ? item.nombre_archivo : 'No se ha cargado ningún archivo'}</p>
                                    <div className="d-flex flex-wrap mb-2">

                                        {
                                            uploadLoading && validate === item.codigo ? <Preload /> : <div className="row" style={{ display: 'flex', width: '100%' }}>
                                                <div className="col-md-12">
                                                    <div className="card h-100 flex-center bg-light-primary border-primary border border-dashed p-8">
                                                        <img src="/media/svg/files/upload.svg" className="mb-5" alt="" onClick={(event) => _onOpenFile(index, event)} />
                                                        <div>
                                                            <input
                                                                id={item.codigo}
                                                                style={{ display: 'none' }}
                                                                ref={(input) => (fileRefs.current[index] = input as HTMLInputElement)}
                                                                type="file"
                                                                accept={item.tipos}
                                                                onChange={(event) => {
                                                                    let files = event.currentTarget.files;
                                                                    if (files) {
                                                                        setValidate(item.codigo)
                                                                        uploadFile(files, item.codigo)
                                                                    }
                                                                }}
                                                            />
                                                            <div className='text-center'>
                                                                <a className="text-hover-primary fs-5 fw-bold mb-2" onClick={(e) => _onOpenFile(index, e)}>
                                                                    Subir archivo
                                                                </a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div className="form-text text-truncate" title={item.tipos}>Archivos permitidos:{item.tipos}</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </a>
                        </div>
                    })
            }
        </>
    );
};

export { List };