import { format } from 'date-fns';

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname

export const formatDate = ( date: string) => {
    return new Date(date).toISOString().split('T')[0]
 }
 export const formatPrice = ( price: number, locale: string, currency: string, minDigits: number, maxDigits: number) => {
    return Number(price).toLocaleString(locale, {
       style: 'currency',
       currency: currency,
       minimumFractionDigits: minDigits,
       maximumFractionDigits: maxDigits,
     })
 }
 export const maskPhoneNumber = (inputPhoneNumber: string): string => {
   const numericValue = inputPhoneNumber.replace(/[^\d]/g, '');
   let maskedValue = '';
   for (let i = 0; i < numericValue.length && i < 10; i++) {
     if (i === 0) maskedValue += `${numericValue[i]}`;
     else if (i === 2) maskedValue += ` ${numericValue[i]}`;
     else if (i === 6 ) maskedValue += ` ${numericValue[i]}`;
     else maskedValue += numericValue[i];
   }
   return maskedValue;
 };
 export const cleanPhoneNumber = (phoneNumber: string): string => {
   const phone = phoneNumber.replace(/\D/g, '');
   return phone;
 };

 export const generarNumeroRandom = () =>{
  // Generar un número decimal aleatorio entre 0 y 1
  const numeroDecimal = Math.random();

  // Multiplicar por 10^10 para obtener un número con 10 dígitos
  const numeroCon10Digitos = Math.floor(numeroDecimal * Math.pow(10, 10));

  // Convertir el número a cadena y asegurarse de que tenga 10 dígitos
  const numeroFinal = String(numeroCon10Digitos).padStart(10, '0');

  return numeroFinal;
}

export const formatearFecha = (fecha: any) => {
  const dia = fecha.getUTCDate().toString().padStart(2, '0');
  const mes = (fecha.getUTCMonth() + 1).toString().padStart(2, '0');
  const año = fecha.getUTCFullYear();
  return `${dia}-${mes}-${año}`;
};
