import React, { useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import {
  KTSVG,
  maskPhoneNumber,
  cleanPhoneNumber,
  generarNumeroRandom,
} from '../../../../../rbts/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { NumericFormat } from 'react-number-format';

type Props = {
  show: boolean;
  dataEdit: any;
  title: string;
  handleClose: (updateGrid: boolean) => void;
  handleEdit: (data: boolean) => void;
};
const dataWorkExperienceSchema = Yup.object().shape({
  empresa: Yup.string().required('Requerido.'),
  motivo_salida: Yup.string().required('Requerido.'),
  asignado_a: Yup.string(),
  telefono_consultoria_format: Yup.string()
    .min(10, 'Formato inválido'),
  telefono_asignacion_format: Yup.string()
    .min(10, 'Formato inválido'),
  proyecto: Yup.string(),
  nombre_jefe: Yup.string(),
  puesto_jefe: Yup.string(),
  sueldo_actual: Yup.number().min(1, 'Incorrecto.').required('Requerido.'),
  prestaciones: Yup.string()
  .max(100, "Máximo caracteres alcanzado.")
  .required("Requerido."),
  actividades: Yup.string()
  .max(2000, "Máximo caracteres alcanzado.")
  .required("Requerido."),
  herramientas_proyecto: Yup.string()
  .max(200, "Máximo caracteres alcanzado.")
  .required("Requerido."),
  trabajo_actual: Yup.boolean(),
  fecha_ingreso: Yup.date().required('Fecha inicio es requerido.'),
  fecha_fin: Yup.date().when('trabajo_actual', {
    is: false,
    then: Yup.date().required('Requerido.'),
    otherwise: Yup.date(),
  }),
});

const WorkExperienceModal: React.FC<Props> = ({
  show,
  dataEdit,
  title,
  handleClose,
  handleEdit,
}) => {
  const [loading, setLoading] = useState(false);

  const WorkExperienceForm = useFormik({
    initialValues: dataEdit,
    validationSchema: dataWorkExperienceSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        if (!values.id) {
          values.es_actual = true;
        }
        
       values.id = values.id === 0 ? generarNumeroRandom() : values.id;
        // values.id = values.id === 0 ? null : values;
        handleEdit(values);
        WorkExperienceForm.resetForm();
        setLoading(false);
      }, 1000);
    },
  });

  const handlePhoneChange = (values: any) => {
    const value = values.target.value.toString();
    const maskedPhoneNumber = maskPhoneNumber(value);
    WorkExperienceForm.setFieldValue('telefono_consultoria', maskedPhoneNumber);
    WorkExperienceForm.setFieldValue(
      'telefono_consultoria_format',
      cleanPhoneNumber(maskedPhoneNumber)
    );
  };
  const handlePhoneAsignChange = (values: any) => {
    const value = values.target.value.toString();
    const maskedPhoneNumber = maskPhoneNumber(value);
    WorkExperienceForm.setFieldValue('telefono_asignacion', maskedPhoneNumber);
    WorkExperienceForm.setFieldValue(
      'telefono_asignacion_format',
      cleanPhoneNumber(maskedPhoneNumber)
    );
  };

  const handleOnChangeWorking = (e: React.ChangeEvent<HTMLInputElement>) => {
    const radioValue = Number(e.target.value);
    WorkExperienceForm.setFieldValue(
      'trabajo_actual',
      radioValue === 1 ? true : false
    );
    WorkExperienceForm.setFieldTouched('fecha_fin', false, false);
  };

  return (
    <Modal
      id="kt_modal_work"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-xl"
      show={show}
      onHide={() => {
        WorkExperienceForm.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header " id="kt_modal_work_header">
          <h2> {title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              WorkExperienceForm.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5">
          <form
            id="kt_modal_detail_form"
            className="form"
            action="#"
            onSubmit={WorkExperienceForm.handleSubmit}
          >
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="w-100 pe-5 mb-10">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto">
                      ¿Es tu trabajo actual?
                    </label>
                  </div>
                  <div className="w-100 d-flex mt-3">
                    <label className="form-check-label fw-bolder text-gray-800 w-50">
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        value={1}
                        onChange={handleOnChangeWorking}
                        checked={
                          WorkExperienceForm.values.trabajo_actual === true
                        }
                        name="trabajo_actual"
                      />
                      Si
                    </label>
                    <label className="form-check-label fw-bolder text-gray-800 w-50">
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        checked={
                          WorkExperienceForm.values.trabajo_actual === false
                        }
                        value={2}
                        onChange={handleOnChangeWorking}
                        name="trabajo_actual"
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto required">
                      Empresa o consultoría
                    </label>
                    {WorkExperienceForm.touched.empresa &&
                      WorkExperienceForm.errors.empresa && (
                        <div className="fv-plugins-message-container text-danger w-100 ms-5">
                          <span role="alert">
                            {WorkExperienceForm.errors.empresa}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    className="form-control mb-3"
                    type="text"
                    maxLength={50}
                    minLength={3}
                    placeholder="Empresa o consultoría"
                    {...WorkExperienceForm.getFieldProps('empresa')}
                  ></input>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto">
                      Asignado a
                    </label>
                  </div>
                  <input
                    className="form-control mb-3"
                    type="text"
                    maxLength={50}
                    minLength={3}
                    placeholder="Asignado a"
                    {...WorkExperienceForm.getFieldProps('asignado_a')}
                  ></input>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto">
                      Teléfono de consultoría
                    </label>
                  </div>
                  <input
                    className="form-control mb-3"
                    value={WorkExperienceForm.values.telefono_consultoria}
                    placeholder="Teléfono de consultoría"
                    prefix=""
                    onChange={handlePhoneChange}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto">
                      Teléfono de asignación
                    </label>
                  </div>
                  <input
                    className="form-control mb-3"
                    value={WorkExperienceForm.values.telefono_asignacion}
                    placeholder="Teléfono de asignación"
                    prefix=""
                    onChange={handlePhoneAsignChange}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto required">
                      Fecha ingreso
                    </label>
                    {WorkExperienceForm.touched.fecha_ingreso &&
                      WorkExperienceForm.errors.fecha_ingreso && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">
                            {WorkExperienceForm.errors.fecha_ingreso}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    type="date"
                    className="form-control mb-3"
                    // onKeyDown={(e) => e.preventDefault()}
                    value={
                      new Date(WorkExperienceForm.values.fecha_ingreso)
                        .toISOString()
                        .split('T')[0]
                    }
                    onChange={(e) => {
                      if (e.target.value) {
                        const fecha = new Date(e.target.value)
                          .toISOString()
                          .split('T')[0];
                        WorkExperienceForm.setFieldValue(
                          'fecha_ingreso',
                          fecha
                        );
                      }
                    }}
                  ></input>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                <div className="w-100 pe-5 ">
                  <div className="w-100 d-flex">
                    <label className={"fw-bold fs-6 mb-2 w-auto" + (!WorkExperienceForm.values.trabajo_actual ? " required" : "")}>
                      Fecha fin
                    </label>

                    {WorkExperienceForm.values.trabajo_actual && WorkExperienceForm.touched.fecha_fin &&
                      WorkExperienceForm.errors.fecha_fin && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">
                            {WorkExperienceForm.errors.fecha_fin}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    type="date"
                    className="form-control mb-3"
                    disabled={WorkExperienceForm.values.trabajo_actual}
                    // onKeyDown={(e) => e.preventDefault()}
                    value={
                      new Date(WorkExperienceForm.values.fecha_fin)
                        .toISOString()
                        .split('T')[0]
                    }
                    onChange={(e) => {
                      if (e.target.value) {
                        const fecha = new Date(e.target.value)
                          .toISOString()
                          .split('T')[0];
                        WorkExperienceForm.setFieldValue('fecha_fin', fecha);
                      }
                    }}
                  ></input>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto">
                      Proyecto o área
                    </label>
                    {WorkExperienceForm.touched.proyecto &&
                      WorkExperienceForm.errors.proyecto && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">
                            {WorkExperienceForm.errors.proyecto}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    className="form-control mb-3"
                    type="text"
                    maxLength={50}
                    minLength={3}
                    placeholder="Proyecto o área"
                    {...WorkExperienceForm.getFieldProps('proyecto')}
                  ></input>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto">
                      Rol
                    </label>
                    {WorkExperienceForm.touched.rol &&
                      WorkExperienceForm.errors.rol && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">
                            {WorkExperienceForm.errors.rol}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    className="form-control mb-3"
                    type="text"
                    maxLength={50}
                    minLength={3}
                    placeholder="Rol"
                    {...WorkExperienceForm.getFieldProps('rol')}
                  ></input>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto">
                      Nombre de jefe inmediato
                    </label>
                    {WorkExperienceForm.touched.nombre_jefe &&
                      WorkExperienceForm.errors.nombre_jefe && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">
                            {WorkExperienceForm.errors.nombre_jefe}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    className="form-control mb-3"
                    type="text"
                    maxLength={40}
                    minLength={3}
                    placeholder="Nombre de jefe inmediato"
                    {...WorkExperienceForm.getFieldProps('nombre_jefe')}
                  ></input>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto">
                      Puesto de jefe inmediato
                    </label>
                    {WorkExperienceForm.touched.puesto_jefe &&
                      WorkExperienceForm.errors.puesto_jefe && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">
                            {WorkExperienceForm.errors.puesto_jefe}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    className="form-control mb-3"
                    type="text"
                    maxLength={35}
                    placeholder="Puesto de jefe inmediato"
                    {...WorkExperienceForm.getFieldProps('puesto_jefe')}
                  ></input>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto required">
                      Sueldo actual
                    </label>
                    {WorkExperienceForm.touched.sueldo_actual &&
                      WorkExperienceForm.errors.sueldo_actual && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">
                            {WorkExperienceForm.errors.sueldo_actual}
                          </span>
                        </div>
                      )}
                  </div>
                  <NumericFormat
                    name="sueldo_actual"
                    className="form-control mb-3"
                    value={WorkExperienceForm.values.sueldo_actual}
                    decimalScale={2}
                    decimalSeparator="."
                    fixedDecimalScale
                    placeholder="$ 0.00"
                    prefix="$ "
                    maxLength={15}
                    thousandSeparator=","
                    onValueChange={(values) => {
                      const { floatValue } = values;
                      WorkExperienceForm.setFieldValue(
                        'sueldo_actual',
                        floatValue ? floatValue : 0
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto required">
                      Motivo de salida
                    </label>
                    {WorkExperienceForm.touched.motivo_salida &&
                      WorkExperienceForm.errors.motivo_salida && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">
                            {WorkExperienceForm.errors.motivo_salida}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    className="form-control mb-3"
                    type="text"
                    maxLength={80}
                    placeholder=" Motivo de salida"
                    // disabled={WorkExperienceForm.values.trabajo_actual}
                    {...WorkExperienceForm.getFieldProps('motivo_salida')}
                  ></input>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto required">
                      Actividades
                    </label>
                    {WorkExperienceForm.touched.actividades &&
                      WorkExperienceForm.errors.actividades && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">
                            {WorkExperienceForm.errors.actividades}
                          </span>
                        </div>
                      )}
                  </div>
                  <textarea
                    className="form-control"
                    maxLength={2000}
                    rows={2}
                    placeholder="Breve descripción de actividades"
                    {...WorkExperienceForm.getFieldProps('actividades')}
                    style={{ resize: 'none' }}
                  ></textarea>
                  <div className="text-muted fs-7 mb-5">
                        {WorkExperienceForm.values.actividades.length} / 2000
                      </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto required">
                      Herramientas del proyecto
                    </label>
                    {WorkExperienceForm.touched.herramientas_proyecto &&
                      WorkExperienceForm.errors.herramientas_proyecto && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">
                            {WorkExperienceForm.errors.herramientas_proyecto}
                          </span>
                        </div>
                      )}
                  </div>
                  <textarea
                    className="form-control"
                    placeholder=" Herramientas del proyecto ejemplo:
                    Eclipse, Git, Maven, JUnit, Jenkins, JIRA, Confluence
                    "
                    {...WorkExperienceForm.getFieldProps(
                      'herramientas_proyecto'
                    )}
                    maxLength={200}
                    rows={3}
                    style={{ resize: 'none' }}
                  ></textarea>
                   <div className="text-muted fs-7 mb-5">
                        {WorkExperienceForm.values.herramientas_proyecto.length} / 200
                      </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto required">
                      Prestaciones
                    </label>
                    {WorkExperienceForm.touched.prestaciones &&
                      WorkExperienceForm.errors.prestaciones && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">
                            {WorkExperienceForm.errors.prestaciones}
                          </span>
                        </div>
                      )}
                  </div>
                  <textarea
                    className="form-control"
                    rows={4}
                    maxLength={100}
                    placeholder=""
                    {...WorkExperienceForm.getFieldProps('prestaciones')}
                    style={{ resize: 'none' }}
                  ></textarea>
                   <div className="text-muted fs-7 mb-5">
                        {WorkExperienceForm.values.prestaciones.length} / 100
                      </div>
                </div>
              </div>
            </div>
          </form>

          <div className="text-center pt-8">
            <button
              type="reset"
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => {
                WorkExperienceForm.resetForm();
                handleClose(false);
              }}
            >
              {title === 'Detalle Experiencia Profesional'
                ? 'Cerrar'
                : 'Cancelar'}
            </button>
            <button
              type="button"
              onClick={() => {
                WorkExperienceForm.submitForm();
              }}
              className="btn btn-primary"
              data-kt-users-modal-action="submit"
              disabled={loading}
            >
              {!loading && <span className="indicator-label">{title === 'Detalle Experiencia Profesional'
                ? 'Editar'
                : 'Guardar'}</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: 'block' }}
                >
                  Espere por favor...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>

          </div>
        </div>
      </div>
    </Modal>
  );
};
export { WorkExperienceModal };
