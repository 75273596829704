import { FC } from 'react';
import { PageTitle, PageLink } from '../../../../rbts/layout/core';
import { View } from './components/View';
import { useParams } from 'react-router-dom';

const breadCrumbs: Array<PageLink> = [];

const CandidatesDetailPage: FC = () => {
  let { id }: { id: string } = useParams();

  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>Detalle CV</PageTitle>
      <View id={Number(id)}></View>
    </>
  );
};

export { CandidatesDetailPage };
