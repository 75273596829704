/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { register } from '../redux/AuthCRUD';
import { Link } from 'react-router-dom';
import { SimpleAlert } from '../../../../rbts/helpers';

const initialValues = {
  first_name: '',
  father_last_name: '',
  mother_last_name: '',
  email: '',
  password: '',
  confirm_password: '',
};

const registrationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(4, 'Mínimo 4 caracteres')
    .max(20, 'Máximo 20 caracteres')
    .required('Requerido.'),
  father_last_name: Yup.string()
    .min(4, 'Mínimo 4 caracteres')
    .max(20, 'Máximo 20 caracteres')
    .required('Requerido.'),
  mother_last_name: Yup.string()
    .min(4, 'Mínimo 4 caracteres')
    .max(50, 'Maximum 50 symbols'),
  email: Yup.string()
    .email('Formato inválido.')
    .min(3, 'Mínimo 3 caracteres.')
    .max(50, 'Máximo 50 caracteres.')
    .required('Requerido.'),
  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=.*[0-9])(?=.*[a-z])(?=\S+$).{8,20}$/,
      'Formato de contraseña incorrecta'
    )
    .required('Contraseña es requerido.'),
  confirm_password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=.*[0-9])(?=.*[a-z])(?=\S+$).{8,20}$/,
      'Formato de contraseña incorrecta'
    )
    .required('Contraseña es requerido.')
    .oneOf([Yup.ref('password'), null], 'La contraseña no coincide'),
});

export function Registration() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [visivility, setVisivility] = useState(false);
  const validateVisibility = () => {
    setVisivility(!visivility);
  };
  const [visivilityPass, setVisivilityPass] = useState(false);
  const validateVisibilityPass = () => {
    setVisivilityPass(!visivilityPass);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        register(values)
          .then(() => {
            SimpleAlert(
              '¡Confirma tu cuenta haciendo clic en el enlace que enviamos a tu correo!',
              6000,
              'success'
            );
            history.push('/login');

            formik.resetForm();

            setLoading(false);
          })
          .catch((err: any) => {
            const {
              data: { message },
            } = err.response;
            setLoading(false);
            setSubmitting(false);
            setStatus(message);
          });
      }, 1000);
    },
  });

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const value = event.target.value;
    const valueReg = value.replace(/\d/g, '');
    const cleanInput = valueReg.replace(/^\s+|\s+(?=\s)/g, '');
    const inputLimpio = cleanInput.replace(/[^\wáéíóúüÁÉÍÓÚÜñÑ\s]/g, '');
    formik.setFieldValue(key, inputLimpio);
  };

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="mb-10 text-center">
        <h1 className="text-dark mb-3">Registrarme</h1>
      </div>

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6 required">
          Nombre
        </label>
        <input
          placeholder="Nombre"
          {...formik.getFieldProps('first_name')}
          className={clsx(
            'form-control form-control-lg',
            {
              'is-invalid':
                formik.touched.first_name && formik.errors.first_name,
            },
            {
              'is-valid':
                formik.touched.first_name && !formik.errors.first_name,
            }
          )}
          type="text"
          name="first_name"
          autoComplete="off"
          onChange={(e: any) => {
            handleChangeInput(e, 'first_name');
          }}
        />
        {formik.touched.first_name && formik.errors.first_name && (
          <div className="fv-plugins-message-container text-danger mt-3">
            <span role="alert">{formik.errors.first_name}</span>
          </div>
        )}
      </div>

      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6 required">
          Apellido Paterno
        </label>
        <input
          placeholder="Apellido Paterno"
          {...formik.getFieldProps('father_last_name')}
          className={clsx(
            'form-control form-control-lg',
            {
              'is-invalid':
                formik.touched.father_last_name &&
                formik.errors.father_last_name,
            },
            {
              'is-valid':
                formik.touched.father_last_name &&
                !formik.errors.father_last_name,
            }
          )}
          type="text"
          name="father_last_name"
          autoComplete="off"
          onChange={(e: any) => {
            handleChangeInput(e, 'father_last_name');
          }}
        />

        {formik.touched.father_last_name && formik.errors.father_last_name && (
          <div className="fv-plugins-message-container text-danger mt-3">
            <span role="alert">{formik.errors.father_last_name}</span>
          </div>
        )}
      </div>
      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6">
          Apellido Materno
        </label>
        <input
          placeholder="Apellido Materno"
          {...formik.getFieldProps('mother_last_name')}
          className={clsx(
            'form-control form-control-lg',
            {
              'is-invalid':
                formik.touched.mother_last_name &&
                formik.errors.mother_last_name,
            },
            {
              'is-valid':
                formik.touched.mother_last_name &&
                !formik.errors.mother_last_name,
            }
          )}
          type="text"
          name="mother_last_name"
          autoComplete="off"
          onChange={(e: any) => {
            handleChangeInput(e, 'mother_last_name');
          }}
        />
        {formik.touched.mother_last_name && formik.errors.mother_last_name && (
          <div className="fv-plugins-message-container text-danger mt-3">
            <span role="alert">{formik.errors.mother_last_name}</span>
          </div>
        )}
      </div>
      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6 required">
          Correo
        </label>
        <input
          placeholder="Correo"
          type="email"
          autoComplete="off"
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container text-danger mt-3">
            <span role="alert">{formik.errors.email}</span>
          </div>
        )}
      </div>

      <div className="mb-10 fv-row" data-kt-password-meter="true">
        <div className="mb-1">
          <label className="form-label fw-bolder text-dark fs-6 required">
            Contraseña
          </label>
          <div className={clsx('position-relative')}>
            <input
              type={visivilityPass ? 'text' : 'password'}
              placeholder="Contraseña"
              autoComplete="off"
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg',
                {
                  'is-invalid':
                    formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid':
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />

            <span
              className={clsx(
                'btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-4'
              )}
              data-kt-password-meter-control={'visibility'}
              onClick={validateVisibilityPass}
            >
              {visivilityPass ? (
                <i className={clsx('bi bi-eye fs-2')}></i>
              ) : (
                <i className={clsx('bi bi-eye-slash fs-2')}></i>
              )}
            </span>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container text-danger mt-3">
              <span role="alert">{formik.errors.password}</span>
            </div>
          )}
          {/* <div className="position-relative mb-3">
            <input
              type="password"
              placeholder="Contraseña"
              autoComplete="off"
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg',
                {
                  'is-invalid':
                    formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid':
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container text-danger mt-3">
                <span role="alert">{formik.errors.password}</span>
              </div>
            )}
          </div> */}
        </div>
      </div>

      <div className="fv-row mb-5">
        <label className="form-label fw-bolder text-dark fs-6 required">
          Confirmar contraseña
        </label>

        <div className={clsx('position-relative')}>
          <input
            type={visivility ? 'text' : 'password'}
            placeholder="Confirmar contraseña"
            autoComplete="off"
            {...formik.getFieldProps('confirm_password')}
            className={clsx(
              'form-control form-control-lg',
              {
                'is-invalid':
                  formik.touched.confirm_password &&
                  formik.errors.confirm_password,
              },
              {
                'is-valid':
                  formik.touched.confirm_password &&
                  !formik.errors.confirm_password,
              }
            )}
          />

          <span
            className={clsx(
              'btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-4'
            )}
            data-kt-password-meter-control={'visibility'}
            onClick={validateVisibility}
          >
            {visivility ? (
              <i className={clsx('bi bi-eye fs-2')}></i>
            ) : (
              <i className={clsx('bi bi-eye-slash fs-2')}></i>
            )}
          </span>
        </div>
        {formik.touched.confirm_password && formik.errors.confirm_password && (
          <div className="fv-plugins-message-container text-danger mt-3">
            <span role="alert">{formik.errors.confirm_password}</span>
          </div>
        )}
        {/* <input
          type="password"
          placeholder="Confirmar contraseña"
          autoComplete="off"
          {...formik.getFieldProps('confirm_password')}
          className={clsx(
            'form-control form-control-lg',
            {
              'is-invalid':
                formik.touched.confirm_password &&
                formik.errors.confirm_password,
            },
            {
              'is-valid':
                formik.touched.confirm_password &&
                !formik.errors.confirm_password,
            }
          )}
        /> */}
      </div>
      <div className="form-text mb-5">
        Las contraseña debe ser al menos de 8 caracteres, contener mayúscula,
        mínusculas, números y algún signo [@#$%^&-+=()]]
      </div>

      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_up_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Crea cuenta</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Espere por favor...{' '}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/auth/login">
          <button
            type="button"
            id="kt_login_signup_form_cancel_button"
            className="btn btn-lg btn-light-primary w-100 mb-5"
          >
            Cancelar
          </button>
        </Link>
      </div>
    </form>
  );
}
