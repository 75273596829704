import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getMyDocuments } from '../../../service/dashboard/Dashboard';
import { DocumentCandidateModel } from '../models/CandidatesDocuments';

export const useGetDocumentsCandidates = (realoadGrid: number) => {

    const [data, setData] = useState<DocumentCandidateModel[]>([]);
    const [count, setCount] = useState(1);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const history = useHistory()

    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0)
            const res = await getMyDocuments().then().catch((error) => {
                history.push('/error/500')
            });
            if (res) {
                setData(res);
                setCount(res.length);
                setLoadingRequest(1);
            }
        };
        if (realoadGrid) {
            fetchPost();
        }
    }, [realoadGrid]);
    return { data, count, loadingRequest }
}