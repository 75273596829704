import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { FallbackView } from '../../rbts/partials';
import { userHasRole } from '../../rbts/helpers';
import { UserModel } from '../modules/auth/models/UserModel';
import { RootState } from '../../setup';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { CandidatesCVPage } from '../pages/candidates_cv/CandidatesCV';
import { CandidatesAddPage } from '../pages/candidates/candidates_add/CandidatesAdd';
import { CandidatesDetailPage } from '../pages/candidates/candidates_detail/CandidatesDetail';
import { CandidatesDocumentsPage } from '../pages/candidates_doc/CandidatesDocuments';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { UsuariosPage } from '../pages/usuarios/Usuarios';
import { GenericoPage } from '../pages/catalogos/generico/Generico';
import { TestPage } from '../pages/catalogos/test';
import { ApiKeyPage } from '../pages/apiKey/ApiKey';
import { EmpresasPage } from '../pages/empresas/Empresas';

export function PrivateRoutes() {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {/* DASHBOARD */}
        <Route
          path="/dashboard"
          component={
            userHasRole(['ADMIN', 'ADMIN_COMPANY', 'RRHH', 'CDTO'], userRoles)
              ? DashboardWrapper
              : ErrorsPage
          }
        />
        <Route
          path="/candidato_cv"
          component={
            userHasRole(['CDTO'], userRoles)
              ? CandidatesAddPage
              : ErrorsPage
          }
        />
        <Route
          path="/cv/candidates"
          component={
            userHasRole(['ADMIN', 'ADMIN_COMPANY', 'RRHH'], userRoles)
              ? CandidatesCVPage
              : ErrorsPage
          }
        />
        <Route
          path="/candidato_detalle/:id"
          component={
            userHasRole(['ADMIN', 'ADMIN_COMPANY', 'RRHH'], userRoles)
              ? CandidatesDetailPage
              : ErrorsPage
          }
        />
        <Route
          path="/documents/cv/"
          component={
            userHasRole(['CDTO'], userRoles)
              ? CandidatesDocumentsPage
              : ErrorsPage
          }
        />

        {/* CATALOGOS */}
        <Route
          path="/catalogos/prueba/"
          component={userHasRole(['ADMIN'], userRoles) ? TestPage : ErrorsPage}
        />
        <Route
          path="/catalogos/generico/:code"
          component={
            userHasRole(['ADMIN'], userRoles) ? GenericoPage : ErrorsPage
          }
        />
        {/* USUARIOS DEL SISTEMA */}
        <Route
          path="/usuarios"
          component={
            userHasRole(['ADMIN'], userRoles) ? UsuariosPage : ErrorsPage
          }
        />
        <Route
          path="/empresas"
          component={
            userHasRole(['ADMIN'], userRoles) ? EmpresasPage : ErrorsPage
          }
        />
        <Route
          path="/api_keys/"
          component={
            userHasRole(['ADMIN'], userRoles) ? ApiKeyPage : ErrorsPage
          }
        />
        <Route path="/perfil" component={ProfilePage} />
        <Redirect from="/auth" to="/dashboard" />
        <Redirect exact from="/" to="/dashboard" />
        <Redirect to="/error/404" />
      </Switch>
    </Suspense>
  );
}
