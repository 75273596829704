/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { PageTitle } from '../../../rbts/layout/core';
import { View } from './components/View';

const DashboardWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Inicio</PageTitle>
      <View />
    </>
  );
};

export { DashboardWrapper };
