import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap-v5";
import { KTSVG, generarNumeroRandom } from "../../../../../rbts/helpers";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelectGeneric } from "../../../catalogos/generico/hooks/genericoHook";

type Props = {
  show: boolean;
  dataEdit: any;
  title: string;
  handleClose: (updateGrid: boolean) => void;
  handleEdit: (data: boolean) => void;
};

const dataCareerSchema = Yup.object().shape({
  desarrollo: Yup.object({
    id: Yup.number().min(1, "Requerido").required("Requerido."),
  }),
  en_curso: Yup.boolean(),
  fecha_inicio: Yup.date(),
  fecha_fin: Yup.date().when("en_curso", {
    is: false,
    then: Yup.date().required("Requerido."),
    otherwise: Yup.date(),
  }),
  nombre: Yup.string().required("Requerido"),
});

const CareerModal: React.FC<Props> = ({
  show,
  dataEdit,
  title,
  handleClose,
  handleEdit,
}) => {
  const [loading, setLoading] = useState(false);
  const { catalog: des_prof } = useSelectGeneric("DPROF");

  const CareerForm = useFormik({
    initialValues: dataEdit,
    validationSchema: dataCareerSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        if (!values.id) {
          values.es_actual = true;
        }
        values.id = values.id === 0 ? generarNumeroRandom() : values.id;
        handleEdit(values);
        CareerForm.resetForm();
        setLoading(false);
      }, 1000);
    },
  });

  const handleOnChangeCareer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const radioValue = Number(e.target.value);
    CareerForm.setFieldValue(
      "en_curso",
      radioValue === 1 ? true : false
    );
    CareerForm.setFieldTouched("fecha_fin", false, false);
  };

  return (
    <Modal
      id="kt_modal_work"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-xl"
      show={show}
      onHide={() => {
        CareerForm.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header " id="kt_modal_work_header">
          <h2> {title} </h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              CareerForm.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5">
          <form
            id="kt_modal_detail_form"
            className="form"
            action="#"
            onSubmit={CareerForm.handleSubmit}
          >
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="w-100 pe-5 mb-10">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto">
                      ¿En curso?
                    </label>
                  </div>
                  <div className="w-100 d-flex mt-3">
                    <label className="form-check-label fw-bolder text-gray-800 w-50">
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        value={1}
                        onChange={handleOnChangeCareer}
                        checked={CareerForm.values.en_curso === true}
                        name="en_curso"
                      />
                      Si
                    </label>
                    <label className="form-check-label fw-bolder text-gray-800 w-50">
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        checked={CareerForm.values.en_curso === false}
                        value={2}
                        onChange={handleOnChangeCareer}
                        name="en_curso"
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-3">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="required form-label w-auto">
                      Desarrollo Profesional
                    </label>
                    {/* {CareerForm.touched.desarrollo?.id &&
                        CareerForm.errors.desarrollo?.id && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {CareerForm.errors.desarrollo.id}
                            </span>
                          </div>
                        )} */}
                  </div>
                  <select
                    className="form-select"
                    data-kt-select2="true"
                    data-placeholder="Select option"
                    data-allow-clear="true"
                    {...CareerForm.getFieldProps("desarrollo.id")}
                  >
                    <option value={0}>Seleccione</option>
                    {des_prof.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-7 col-xxl-7">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto required">
                      Nombre
                    </label>
                    {CareerForm.touched.nombre && CareerForm.errors.nombre && (
                      <div className="fv-plugins-message-container text-danger w-auto ms-5">
                        <span role="alert">{CareerForm.errors.nombre}</span>
                      </div>
                    )}
                  </div>
                  <input
                    className="form-control mb-3"
                    type="text"
                    placeholder="Nombre"
                    {...CareerForm.getFieldProps("nombre")}
                  ></input>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto required">
                      Fecha inicio
                    </label>
                    {CareerForm.touched.fecha_inicio &&
                      CareerForm.errors.fecha_inicio && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">
                            {CareerForm.errors.fecha_inicio}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    type="date"
                    className="form-control"
                    // onKeyDown={(e) => e.preventDefault()}
                    value={
                      new Date(CareerForm.values.fecha_inicio)
                        .toISOString()
                        .split("T")[0]
                    }
                    onChange={(e) => {
                      if (e.target.value) {
                        const fecha = new Date(e.target.value)
                          .toISOString()
                          .split("T")[0];
                        CareerForm.setFieldValue("fecha_inicio", fecha);
                      }
                    }}
                  ></input>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label
                      className={
                        "fw-bold fs-6 mb-2 w-auto" +
                        (!CareerForm.values.en_curso
                          ? " required"
                          : "")
                      }
                    >
                      Fecha fin
                    </label>
                    {CareerForm.touched.fecha_fin &&
                      CareerForm.errors.fecha_fin && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">
                            {CareerForm.errors.fecha_fin}
                          </span>
                        </div>
                      )}
                  </div>
                  <input
                    type="date"
                    className="form-control"
                    disabled={CareerForm.values.en_curso}
                    // onKeyDown={(e) => e.preventDefault()}
                    value={
                      new Date(CareerForm.values.fecha_fin)
                        .toISOString()
                        .split("T")[0]
                    }
                    onChange={(e) => {
                      if (e.target.value) {
                        const fecha = new Date(e.target.value)
                          .toISOString()
                          .split("T")[0];
                        CareerForm.setFieldValue("fecha_fin", fecha);
                      }
                    }}
                  ></input>
                </div>
              </div>
            </div>
          </form>

          <div className="text-center pt-8">
            <button
              type="reset"
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => {
                CareerForm.resetForm();
                handleClose(false);
              }}
            >
              {title === "Detalle Formación Académica" ? "Cerrar" : "Cancelar"}
            </button>

            <button
              type="button"
              onClick={() => {
                CareerForm.submitForm();
              }}
              className="btn btn-primary"
              data-kt-users-modal-action="submit"
              disabled={loading}
            >
              {!loading && (
                <span className="indicator-label">
                  {title === "Detalle Experiencia Profesional"
                    ? "Editar"
                    : "Guardar"}
                </span>
              )}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Espere por favor...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { CareerModal };
