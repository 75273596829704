import axios from 'axios';
import { maskPhoneNumber, cleanPhoneNumber } from '../../../rbts/helpers';
const API_URL = process.env.REACT_APP_API;

export async function getCVCandidates(startIndex: number, endIndex: number, filters: any) {

    const { perfil, herramientas, edad_minima, edad_maxima, actividades } = filters;

    const searchData = {
        keywords: perfil,
        tools: herramientas,
        minAge: edad_minima,
        maxAge: edad_maxima,
        activities: actividades,
    };

    const response = await axios.post(`${API_URL}candidatos/curriculum/buscar?limit=${startIndex}&page=${endIndex}`, searchData);
    const data = response.data.doc.data;
    return data;

}

export const candidateAdd = async (values: any) => {
    let response;
    const { id, edad, fecha_nacimiento, religion, lugar_nacimiento, domicilio, telefono_particular_format,
        toma_medicamentos_controlados, sueldo_actual, expectativa_cambio, estadoCivil, genero,
        perfil, medicamentos, movil_format, idiomas, formacionAcademica, experienciaProfesional, desarrolloProfresional, 
        tiene_credito_infonavit, colonia, municipio, estado, codigo_postal, modalidad, vacantesInteres, habilidades_tecnicas} = values;

//         const experienciaProfesionalActualizada = experienciaProfesional.map((item: any) => {
//   return {
//     ...item,
//     id: item.es_actual ? null : item.id,
//   };
// });
// const formacionAcademicaActualizada = formacionAcademica.map((item: any) => {
//     return {
//       ...item,
//       id: item.es_actual ? null : item.id,
//     };
//   });

    const data = {
        id: (id === 0) ? null : id,
        edad: edad,
        fecha_nacimiento: fecha_nacimiento,
        religion: religion,
        lugar_nacimiento: lugar_nacimiento,
        domicilio: domicilio,
        telefono_particular: telefono_particular_format,
        movil: movil_format,
        toma_medicamentos_controlados: (toma_medicamentos_controlados === 1) ? true : false,
        medicamentos: medicamentos,
        perfil: perfil,
        sueldo_actual: sueldo_actual,
        expectativa_cambio: expectativa_cambio,
        estadoCivil: estadoCivil,
        genero: genero,
        experienciaProfesional: experienciaProfesional,
        formacionAcademica: formacionAcademica,
        idiomas: idiomas,
        desarrolloProfresional: desarrolloProfresional,
        tiene_credito_infonavit: tiene_credito_infonavit,
        colonia: colonia,
        municipio: municipio,
        estado: estado,
        codigo_postal: codigo_postal,
        modalidad: modalidad,
        vacantesInteres: vacantesInteres,
        habilidades_tecnicas: habilidades_tecnicas,
    };
    if (id > 0) {
        response = await axios.put(`${API_URL}candidatos/curriculum/${id}`, data);

    } else {
        response = await axios.post(`${API_URL}candidatos/curriculum`, data);

    }
    return response;
}
export const candidateGet = async () => {
    const response = await axios.get(`${API_URL}candidatos/curriculum/get/me/`);
    let responseData = response.data.doc.data;
    if (responseData) {
        responseData.telefono_particular = maskPhoneNumber(responseData.telefono_particular);
        responseData.telefono_particular_format = cleanPhoneNumber(responseData.telefono_particular);
        responseData.movil = maskPhoneNumber(responseData.movil);
        responseData.movil_format = cleanPhoneNumber(responseData.movil);
        responseData.toma_medicamentos_controlados = (responseData.toma_medicamentos_controlados === false) ? 2 : 1;
        let { experienciaProfesional } = responseData;
        if(experienciaProfesional){
            experienciaProfesional.forEach(function(item: any) {
                item.telefono_asignacion_format = cleanPhoneNumber(item.telefono_asignacion);
                item.telefono_consultoria_format = cleanPhoneNumber(item.telefono_consultoria);
                item.es_actual = false;
            });
        }
    }

    return responseData;
}
export const candidateGetPerID = async (id: number) => {
    const response = await axios.get(`${API_URL}candidatos/curriculum/${id}`);
    let responseData = response.data.doc.data;
    if (responseData) {
        responseData.telefono_particular = maskPhoneNumber(responseData.telefono_particular);
        responseData.telefono_particular_format = cleanPhoneNumber(responseData.telefono_particular);
        responseData.movil = maskPhoneNumber(responseData.movil);
        responseData.movil_format = cleanPhoneNumber(responseData.movil);
        responseData.toma_medicamentos_controlados = (responseData.toma_medicamentos_controlados === false) ? 2 : 1;
    }
    return responseData;
}
export const uploadFiledCV = async (file: any, code: any,) => {

    const formData = new FormData();
    formData.append('file', file);
    formData.append('code', code);

    const response = await axios.post(`${API_URL}candidatos/documentos/`, formData);
    return response;

}
export const getDocumentsByCV = async (id: number) => {

    const response = await axios.get(`${API_URL}candidatos/documentos/cv/${id}`)
    const data = response.data.doc.data;
    const objetosCargados = data.filter((item: any )=> item.cargado === true);
    return objetosCargados;

}

export async function getInformationByCode(code: number) {

    const response = await axios.get(`${API_URL}codigosPostales/${code}`
    );
    const data = response.data.doc.data;
    return {
        data: data,
    };

}
