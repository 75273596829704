import React, { useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG } from '../../../../../rbts/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';

type Props = {
  show: boolean;
  dataEdit: any;
  title: string;
  handleClose: (updateGrid: boolean) => void;
  handleEdit: (data: boolean) => void;
};
const dataVacantSchema = Yup.object().shape({
  nombre: Yup.string().required('Requerido.'),
});

const VacantModal: React.FC<Props> = ({
  show,
  dataEdit,
  title,
  handleClose,
  handleEdit,
}) => {
  const [loading, setLoading] = useState(false);

  const VacantForm = useFormik({
    initialValues: dataEdit,
    validationSchema: dataVacantSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        values.id = values.id === 0 ? null : values.id;
        handleEdit(values);
        VacantForm.resetForm();
        setLoading(false);
      }, 1000);
    },
  });

  return (
    <Modal
      id="kt_modal_work"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-ms"
      show={show}
      onHide={() => {
        VacantForm.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header " id="kt_modal_work_header">
          <h2> {title} </h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
                VacantForm.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5">
          <form
            id="kt_modal_detail_form"
            className="form"
            action="#"
            onSubmit={VacantForm.handleSubmit}
          >
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto required">
                      Nombre
                    </label>
                    {VacantForm.touched.nombre && VacantForm.errors.nombre && (
                      <div className="fv-plugins-message-container text-danger w-100 ms-5">
                        <span role="alert">{VacantForm.errors.nombre}</span>
                      </div>
                    )}
                  </div>
                  <input
                    className="form-control mb-3"
                    type="text"
                    placeholder="Nombre"
                    {...VacantForm.getFieldProps('nombre')}
                  ></input>
                </div>
              </div>
            </div>
          </form>

          <div className="text-center pt-8">
            <button
              type="reset"
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => {
                VacantForm.resetForm();
                handleClose(false);
              }}
            >
              Cancelar
            </button>
            <button
              type="button"
              onClick={() => {
                VacantForm.submitForm();
              }}
              className="btn btn-primary"
              data-kt-users-modal-action="submit"
              disabled={loading}
            >
              {!loading && <span className="indicator-label">Guardar</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: 'block' }}
                >
                  Espere por favor...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { VacantModal };
