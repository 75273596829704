import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function getSelectEmpresas() {
  const response = await axios.get(`${API_URL}catalogos/empresas`);
  const data = response.data.doc.data;
  let dataSelect: any = data.rows;
  dataSelect.forEach((row: any) => {
    row.label = `${row.name}`;
    row.value = row.id;
  });
  return dataSelect;
}

export async function getAllEmpresas(
  filters: any
) {
  let search: string = '';
  if (filters) {
    if (filters.nombre !== '') {
      search += `?nameOpLike=%${filters.nombre}%`;
    }
  }
  const response = await axios.get(
    `${API_URL}catalogos/empresas${search}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function addEmpresas(body: any) {
  const { code, name } = body;
  const data = {
    code: code,
    name: name,
  };
  const response = await axios.post(`${API_URL}catalogos/empresas`, data);
  return response;
}
export async function updateEmpresas(body: any) {
  const { code, name, id } = body;
  const data = {
    code: code,
    name: name,
  };
  const response = await axios.put(`${API_URL}catalogos/empresas/${id}`, data);
  return response;
}
export async function getOneEmpresas(id_row: number) {
  const response = await axios.get(`${API_URL}catalogos/empresas/${id_row}`);
  const { id, code, name } = response.data.doc.data;
  return {
    id: id || 0,
    code: code || '',
    name: name || '',
  };
}
export async function removeEmpresas(id: number) {
  const response = await axios.delete(`${API_URL}catalogos/empresas/${id}`);
  return response;
}
