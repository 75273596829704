import React, { useState, useEffect, ChangeEvent } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  KTSVG,
  Preload,
  maskPhoneNumber,
  cleanPhoneNumber,
  SimpleAlert,
  sortDate,
  formatearFecha,
} from "../../../../../rbts/helpers";
import { ScrollTopComponent } from "../../../../../rbts/assets/ts/components";
import { shallowEqual, useSelector } from "react-redux";
import { UserModel } from "../../../../modules/auth/models/UserModel";
import { RootState } from "../../../../../setup";
import { WorkExperienceModal } from "../_modals/WorkExperienceModal";
import { AcademicModal } from "../_modals/AcademicModal";
import { LenguageModal } from "../_modals/LenguageModal";
import { CareerModal } from "../_modals/CareerProgressModal";
import { VacantModal } from "../_modals/VacantModal";
import { NumericFormat } from "react-number-format";
import { useSelectGeneric } from "../../../catalogos/generico/hooks/genericoHook";
import {
  candidateAdd,
} from "../../../../service/candidates/Candidates";
import { useGetOne, useGetInformationByCode } from "../hooks/AcademicHook";

const initValuesWorkExp = {
  id: 0,
  es_actual: false,
  empresa: "",
  asignado_a: "",
  telefono_consultoria: "",
  telefono_consultoria_format: "",
  telefono_asignacion: "",
  telefono_asignacion_format: "",
  fecha_ingreso: new Date().toISOString().split("T")[0],
  fecha_fin: new Date().toISOString().split("T")[0],
  proyecto: "",
  nombre_jefe: "",
  puesto_jefe: "",
  sueldo_actual: "",
  prestaciones: "",
  motivo_salida: "",
  actividades: "",
  herramientas_proyecto: "",
  trabajo_actual: false,
  rol: "",
};
const initValuesAcademic = {
  id: 0,
  institucion: "",
  carrera: "",
  fecha_inicio: new Date().toISOString().split("T")[0],
  fecha_fin: new Date().toISOString().split("T")[0],
  estudio_actual: false,
};
const initValuesLanguage = {
  id: 0,
  idioma: "",
  nivel: {
    id: 0,
    name: "",
    code: "",
  },
};
const initValuesCareer = {
  id: 0,
  desarrollo: {
    id: 0,
    name: "",
    code: "",
  },
  nombre: "",
  fecha_inicio: new Date().toISOString().split("T")[0],
  fecha_fin: new Date().toISOString().split("T")[0],
  en_curso: false,
};
const initValuesVacant = {
  id: 0,
  nombre: "",
}

const dataCVSchema = Yup.object().shape({
  // edad: Yup.number().min(18, 'Edad inválida').required('Requerido.'),
  fecha_nacimiento: Yup.date()
    .max(new Date(), "La fecha de nacimiento no puede ser en el futuro")
    .test(
      "is-adult",
      "Debes ser mayor de edad para registrarte y menor de 99 años",
      function (value: any) {
        const fechaNacimiento = new Date(value);
        const fechaHoy = new Date();
        const edadLimite = new Date(
          fechaHoy.getFullYear() - 18,
          fechaHoy.getMonth(),
          fechaHoy.getDate()
        );

        const edadLimite99 = new Date(
          fechaHoy.getFullYear() - 99,
          fechaHoy.getMonth(),
          fechaHoy.getDate()
        );

        return fechaNacimiento <= edadLimite && fechaNacimiento >= edadLimite99;
      }
    )
    .required("La fecha de nacimiento es obligatoria"),
  estadoCivil: Yup.object({
    id: Yup.number().min(1, "Requerido").required("Requerido."),
  }),
  genero: Yup.object({
    id: Yup.number().min(1, "Requerido").required("Requerido."),
  }),
  // religion: Yup.object({
  //   id: Yup.number().min(1, 'Requerido').required('Requerido.'),
  // }),
  lugar_nacimiento: Yup.string().required("Requerido."),
  domicilio: Yup.string().required("Requerido."),
  telefono_particular_format: Yup.string().min(10, "Formato inválido"),
  movil_format: Yup.string().required("Requerido.").min(10, "Formato inválido"),
  medicamentos: Yup.string().when("toma_medicamentos_controlados", {
    is: 1,
    then: Yup.string()
      .max(201, "Máximo caracteres alcanzado.")
      .required("Requerido."),
  }),
  vacantesInteres: Yup.array(),
  perfil: Yup.string()
    .max(2000, "Máximo caracteres alcanzado.")
    .required("Requerido."),
  habilidades_tecnicas: Yup.string()
    .nullable()
    .max(2000, "Máximo caracteres alcanzado.")
    .required("Debes ingresar información de tus habilidades técnicas."),
  sueldo_actual: Yup.number().min(1, " Requerido.").required("Requerido."),
  expectativa_cambio: Yup.number().min(1, " Requerido.").required("Requerido."),
  tiene_credito_infonavit: Yup.boolean().required("Requerido."),
  colonia: Yup.string().required("Requerido."),
  selectedColonia: Yup.string().notRequired(),
  municipio: Yup.string().required("Requerido."),
  estado: Yup.string().required("Requerido."),
  codigo_postal: Yup.number().required("Requerido."),
  modalidad: Yup.object({
    id: Yup.number().min(1, "Requerido").required("Requerido."),
  }),
  idiomas: Yup.array()
    .min(1, "Debes ingresar al menos un idioma.")
    .required("Debes ingresar al menos un idioma."),
  formacionAcademica: Yup.array()
    .min(1, "Debes ingresar al menos una formacion academica.")
    .required("Debes ingresar al menos una formacion academica."),
  experienciaProfesional: Yup.array()
    .min(1, "Debes ingresar al menos una experiencia profesional.")
    .required("Debes ingresar al menos una experiencia profesional."),
  desarrolloProfresional: Yup.array()
    .min(1, "Debes ingresar al menos un desarrollo profesional.")
    .required("Debes ingresar al menos un desarrollo profesional."),
});

const View: React.FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [random, setRandom] = useState(Math.random() * 40);
  const { initValues, loadingRequest } = useGetOne(random);
  const [code, setCode] = useState<number>(0);
  const { dataLocation } = useGetInformationByCode(code, true)
  const [optionColonia, setOptionColonia] = useState<boolean>(false);
  const [colonia, setColonia] = useState<string>("");
  const [colonias, setColonias] = useState<any>([]);
  const [showBtn, setShowBtn] = useState<boolean>(true);
  const [indicator, setIndicator] = useState<number>(0);

  // DATOS PERSONALES
  const { catalog: estado_civil } = useSelectGeneric("ECIV");
  const { catalog: genero } = useSelectGeneric("GEN");
  const { catalog: religion } = useSelectGeneric("REL");
  const { catalog: modalidad } = useSelectGeneric("MODTR");

  // INFORMACION GENERAL
  const [loadingGeneralInformation, setLoadingGeneralInfo] = useState<boolean>(true);

  // MODAL EXPERIENCIA LABORAL
  const [showWorkExpModal, setShowWorkExpModal] = useState<boolean>(false);
  const [titleWorkExpModal, setTitleShowWorkExpModal] = useState<string>("");
  const [dataWorkExp, setDataWorkExp] = useState(initValuesWorkExp);
  const [loadingWorkExp, setLoadingWorkExp] = useState<boolean>(true);

  // MODAL FORMACIÓN ACADÉMIC
  const [showAcademicModal, setAcademicModal] = useState<boolean>(false);
  const [titleAcademicModal, setTitleAcademicModal] = useState<string>("");
  const [dataAcademic, setDataAcademic] = useState(initValuesAcademic);
  const [loadingAcademic, setLoadingAcademic] = useState<boolean>(true);

  // MODAL FORMACIÓN LENGUAGE
  const [showLenguageModal, setLenguageModal] = useState<boolean>(false);
  const [titleLenguageModal, setTitleLenguageModal] = useState<string>("");
  const [dataLenguage, setDataLenguage] = useState(initValuesLanguage);
  const [loadingLenguage, setLoadingLenguage] = useState<boolean>(true);

  // MODAL FORMACIÓN CAREER
  const [showCareerModal, setCareerModal] = useState<boolean>(false);
  const [titleCareerModal, setTitleCareerModal] = useState<string>("");
  const [dataCareer, setDataCareer] = useState(initValuesCareer);
  const [loadingCareer, setLoadingCareer] = useState<boolean>(true);

  // MODAL FORMACIÓN VACANT
  const [showVacantModal, setVacantModal] = useState<boolean>(false);
  const [titleVacantModal, setTitleVacantModal] = useState<string>("");
  const [dataVacant, setDataVacant] = useState(initValuesVacant);
  const [loadingVacant, setLoadingVacant] = useState<boolean>(true);

  // HANDLERS
  const addNewWorkExp = () => {
    setTitleShowWorkExpModal("Nueva Experiencia Profesional");
    setShowWorkExpModal(true);
  };
  const addNewAcademic = () => {
    setTitleAcademicModal("Nueva Formación Académica");
    setAcademicModal(true);
  };
  const addNewCareer = () => {
    setTitleCareerModal("Nuevo Desarrollo Profesional");
    setCareerModal(true);
  };
  const addNewLenguage = () => {
    setTitleLenguageModal("Nuevo Idioma");
    setLenguageModal(true);
  };
  const addNewVacante = () => {
    setTitleVacantModal("Nueva Vacante");
    setVacantModal(true);
  };
  const deleteExp = (row: any) => {
    setLoadingWorkExp(true);
    const expExists = cvForm.values.experienciaProfesional;
    const newArray = expExists.filter((item: any) => !sonIguales(item, row));
    cvForm.setFieldValue("experienciaProfesional", newArray);
  };
  const editExp = (row: any) => {
    
    const expExists = cvForm.values.experienciaProfesional;
    const expEdit = expExists.filter((item: any) => sonIguales(item, row));
    setDataWorkExp(expEdit[0]);
    setTitleShowWorkExpModal("Detalle Experiencia Profesional");
    setShowWorkExpModal(true);
  };
  const deleteAcademic = (row: any) => {
    setLoadingAcademic(true);
    const academic = cvForm.values.formacionAcademica;
    const newArray = academic.filter((item: any) => !sonIguales(item, row));
    cvForm.setFieldValue("formacionAcademica", newArray);
  };
  const editAcademic = (row: any) => {
    const academic = cvForm.values.formacionAcademica;
    const academicEdit = academic.filter((item: any) => sonIguales(item, row));
    setDataAcademic(academicEdit[0]);
    setTitleAcademicModal("Detalle Formación Académica");
    setAcademicModal(true);
  };
  const deleteLenguage = (row: any) => {
    setLoadingLenguage(true);
    const idiomas = cvForm.values.idiomas;
    const newArray = idiomas.filter((item: any) => !sonIguales(item, row));
    cvForm.setFieldValue("idiomas", newArray);
  };
  const deleteCareer = (row: any) => {
    setLoadingCareer(true);
    const career = cvForm.values.desarrolloProfresional;
    const newArray = career.filter((item: any) => !sonIguales(item, row));
    cvForm.setFieldValue("desarrolloProfresional", newArray);
  };
  const deleteVacant = (row: any) => {
    setLoadingVacant(true);
    const vacantes = cvForm.values.vacantesInteres;
    const newArray = vacantes.filter((item: any) => !sonIguales(item, row));
    cvForm.setFieldValue("vacantesInteres", newArray);
  };
  const editCareer = (row: any) => {
    const career = cvForm.values.desarrolloProfresional;
    const careerEdit = career.filter((item: any) => sonIguales(item, row));
    setDataCareer(careerEdit[0]);
    setTitleCareerModal("Detalle Desarrollo Profesional");
    setCareerModal(true);
  };
  const sonIguales = (objetoA: any, objetoB: any) => {
    return Object.keys(objetoA).every((key) => objetoA[key] === objetoB[key]);
  };

  const handlePhoneChange = (values: any) => {
    const value = values.target.value.toString();
    const maskedPhoneNumber = maskPhoneNumber(value);
    cvForm.setFieldValue("telefono_particular", maskedPhoneNumber);
    cvForm.setFieldValue(
      "telefono_particular_format",
      cleanPhoneNumber(maskedPhoneNumber)
    );
  };
  const handleMovilChange = (values: any) => {
    const value = values.target.value.toString();
    const maskedPhoneNumber = maskPhoneNumber(value);
    cvForm.setFieldValue("movil", maskedPhoneNumber);
    cvForm.setFieldValue("movil_format", cleanPhoneNumber(maskedPhoneNumber));
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const radioValue = Number(e.target.value);
    cvForm.setFieldValue("toma_medicamentos_controlados", radioValue);
    cvForm.setFieldValue("medicamentos", "");
    cvForm.setFieldTouched("medicamentos", false, false);
  };
  const handleOninfonavitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const radioValue = Number(e.target.value);
    cvForm.setFieldValue(
      "tiene_credito_infonavit",
      radioValue === 1 ? true : false
    );
  };

  const scrollTop = () => {
    ScrollTopComponent.goTop();
  };

  const cvForm = useFormik({
    initialValues: initValues,
    validationSchema: dataCVSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {


      setLoading(true);
      setTimeout(() => {

        values.experienciaProfesional.map((item: any) => {
          if (item.es_actual) {
            item.id = null
          }
        });
        values.formacionAcademica.map((item: any) => {
          if (item.es_actual) {
            item.id = null
          }
        });
        values.desarrolloProfresional.map((item: any) => {
          if (item.es_actual) {
            item.id = null
          }
        });
        candidateAdd(values)
          .then((res) => {
            const {
              data: { message },
            } = res;
            SimpleAlert(message, 4000, "success");
            setLoading(false);
            setRandom(Math.random() * 40);
            // scrollTop();
            history.push("/dashboard");
          })
          .catch((err) => {
            let resMessageToast: string = "";
            const {
              data: { message, details = null },
            } = err.response;
            resMessageToast = `${message}`;
            if (details.length) {
              setStatus(`${details[0]?.message}`);
            }
            setLoading(false);
            setSubmitting(false);
            SimpleAlert(resMessageToast, 3000, "error");
          });
        setLoading(false);
      }, 1000);
    },
  });

  
  useEffect(() => {
    if (loadingRequest > 0) {
      setLoadingLenguage(false);
      setLoadingAcademic(false);
      setLoadingWorkExp(false);
      setLoadingGeneralInfo(false);
    }
  }, [loadingRequest]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoadingWorkExp(false);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [cvForm.values.experienciaProfesional]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoadingAcademic(false);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [cvForm.values.formacionAcademica]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoadingLenguage(false);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [cvForm.values.idiomas]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoadingCareer(false);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [cvForm.values.desarrolloProfresional]);

  useEffect(() => {
    setCode(parseInt(initValues.codigo_postal))
  }, [initValues]);

  useEffect(() => {

    if (!dataLocation?.error && dataLocation) {
      const { codigo_postal } = dataLocation;

      cvForm.setFieldValue(
        'estado',
        codigo_postal?.estado ? codigo_postal?.estado : ''
      );
      cvForm.setFieldValue(
        'municipio',
        codigo_postal?.municipio ? codigo_postal?.municipio : ''
      );

      if (codigo_postal?.colonias.length) {
        setColonia('');
        codigo_postal?.colonias.push('otra');
        setColonias(codigo_postal?.colonias);

        if (initValues.id > 0 && indicator === 0) {

          const validate = codigo_postal.colonias.some((item: any) => {
            return item === initValues.colonia;
          });

          if (validate) {
            setOptionColonia(true);
            setColonia(initValues.colonia)
          } else {
            setOptionColonia(false);
            setShowBtn(true);
            setColonia(initValues.colonia)

          }
        } else {
          setOptionColonia(true);

        }



      } else {
        setOptionColonia(false);
        setColonia('');
        cvForm.setFieldValue('estado', '');
        cvForm.setFieldValue('municipio', '');
        cvForm.setFieldValue('colonia', '');

      }
    }


  }, [dataLocation]);

  useEffect(() => {

    if (!code) {
      setOptionColonia(false);
      setShowBtn(false);
      cvForm.setFieldValue(
        'estado',
        ''
      );
      cvForm.setFieldValue(
        'municipio',
        ''
      );
      cvForm.setFieldValue(
        'colonia',
        ''
      );
      setColonia('')
    }

  }, [code]);

  useEffect(() => {
    cvForm.setFieldValue("colonia", colonia);
  }, [colonia]);



  return (
    <>
      <form
        className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework"
        onSubmit={cvForm.handleSubmit}
      >
        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
          {/* INFORMACION GENERAL */}

          {loadingGeneralInformation ? (
            <div className="text-center">Cargando...</div>
          ) : (
            <div className="card card-flush py-4">
              <div className="card-header">
                <div className="card-title">
                  <h4>Datos Personales</h4>
                </div>
              </div>
              <div className="card-body border-top">
                <div className="row">
                  <div className="col d-flex flex-center flex-column ">
                    <div className="symbol symbol-100px mb-5">
                      <img
                        alt="Logo"
                        src={user.profile_photo}
                        crossOrigin="anonymous"
                      />
                    </div>
                    <a
                      href="#"
                      className="fs-4 text-primary text-hover-primary fw-bold mb-0"
                    >
                      {user.first_name} {user.father_last_name}{" "}
                      {user.mother_last_name}
                    </a>
                    <div className="fw-semibold text-gray-500 mb-6">
                      {user.email}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                    <div className="w-100 pe-5 mt-5">
                      <div className="w-100 d-flex">
                        <label className="required form-label w-auto">
                          Fecha de nacimiento
                        </label>
                      </div>
                      <input
                        type="date"
                        className={`form-control ${cvForm.touched.fecha_nacimiento &&
                          cvForm.errors.fecha_nacimiento
                          ? "is-invalid"
                          : ""
                          }`}
                        id="fecha_nacimiento"
                        name="fecha_nacimiento"
                        value={
                          cvForm.values.fecha_nacimiento
                            ? new Date(cvForm.values.fecha_nacimiento)
                              .toISOString()
                              .split("T")[0]
                            : ""
                        }
                        onChange={cvForm.handleChange}
                        onBlur={cvForm.handleBlur}
                      />
                      {cvForm.touched.fecha_nacimiento &&
                        cvForm.errors.fecha_nacimiento && (
                          <div className="invalid-feedback">
                            {cvForm.errors.fecha_nacimiento}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                    <div className="w-100 pe-5 mt-5">
                      <div className="w-100 d-flex">
                        <label className="required form-label w-auto">
                          Celular
                        </label>
                        {cvForm.touched.movil_format &&
                          cvForm.errors.movil_format && (
                            <div className="fv-plugins-message-container text-danger w-auto ms-5">
                              <span role="alert">
                                {cvForm.errors.movil_format}
                              </span>
                            </div>
                          )}
                      </div>
                      <input
                        className="form-control mb-3"
                        value={cvForm.values.movil}
                        placeholder="Celular"
                        prefix=""
                        onChange={handleMovilChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                    <div className="w-100 pe-5 mt-5">
                      <div className="w-100 d-flex">
                        <label className="form-label w-auto">
                          Teléfono particular/recados
                        </label>
                        {cvForm.touched.telefono_particular_format &&
                          cvForm.errors.telefono_particular_format && (
                            <div className="fv-plugins-message-container text-danger w-auto ms-5">
                              <span role="alert">
                                {cvForm.errors.telefono_particular_format}
                              </span>
                            </div>
                          )}
                      </div>
                      <input
                        className="form-control mb-3"
                        value={cvForm.values.telefono_particular}
                        placeholder="Teléfono particular/recados"
                        prefix=""
                        onChange={handlePhoneChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                    <div className="w-100 pe-5 mt-5">
                      <div className="w-100 d-flex">
                        <label className="required form-label w-auto">
                          Estado Civil
                        </label>
                        {cvForm.touched.estadoCivil?.id &&
                          cvForm.errors.estadoCivil?.id && (
                            <div className="fv-plugins-message-container text-danger w-auto ms-5">
                              <span role="alert">
                                {cvForm.errors.estadoCivil.id}
                              </span>
                            </div>
                          )}
                      </div>
                      <select
                        className="form-select"
                        data-kt-select2="true"
                        data-placeholder="Select option"
                        data-allow-clear="true"
                        {...cvForm.getFieldProps("estadoCivil.id")}
                      >
                        <option value={0}>Seleccione</option>
                        {estado_civil.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>



                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                    <div className="w-100 pe-5 mt-5">
                      <div className="w-100 d-flex">
                        <label className="required form-label w-auto">
                          Género
                        </label>
                        {cvForm.touched.genero?.id && cvForm.errors.genero?.id && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">{cvForm.errors.genero.id}</span>
                          </div>
                        )}
                      </div>
                      <select
                        className="form-select mb-2"
                        {...cvForm.getFieldProps("genero.id")}
                      >
                        <option value={0}>Seleccione</option>
                        {genero.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                    <div className="w-100 pe-5 mt-5">
                      <div className="w-100 d-flex">
                        <label className="required form-label w-auto">
                          Lugar de nacimiento
                        </label>
                        {cvForm.touched.lugar_nacimiento &&
                          cvForm.errors.lugar_nacimiento && (
                            <div className="fv-plugins-message-container text-danger w-auto ms-5">
                              <span role="alert">
                                {cvForm.errors.lugar_nacimiento}
                              </span>
                            </div>
                          )}
                      </div>
                      <input
                        type="text"
                        {...cvForm.getFieldProps("lugar_nacimiento")}
                        name="lugar_nacimiento"
                        className="form-control mb-2"
                        placeholder="Lugar de nacimiento"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                    <div className="w-100 pe-5 mt-5">
                      <div className="w-100 d-flex">
                        <label className="form-label w-auto">
                          Tomas algún medicamento controlado
                        </label>
                      </div>
                      <div className="w-100 d-flex mt-3">
                        <label className="form-check-label fw-bolder text-gray-800 w-50">
                          <input
                            className="form-check-input me-3"
                            type="radio"
                            value={1}
                            onChange={handleOnChange}
                            checked={
                              cvForm.values.toma_medicamentos_controlados === 1
                            }
                            name="toma_medicamentos_controlados"
                          />
                          Si
                        </label>
                        <label className="form-check-label fw-bolder text-gray-800 w-50">
                          <input
                            className="form-check-input me-3"
                            type="radio"
                            checked={
                              cvForm.values.toma_medicamentos_controlados === 2
                            }
                            value={2}
                            onChange={handleOnChange}
                            name="toma_medicamentos_controlados"
                          />
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                    <div className="w-100 pe-5 mt-5">
                      <div className="w-100 d-flex">
                        <label className="required form-label w-auto">
                          Indica cuál
                        </label>
                        {cvForm.touched.medicamentos &&
                          cvForm.errors.medicamentos && (
                            <div className="fv-plugins-message-container text-danger w-auto ms-5">
                              <span role="alert">
                                {cvForm.errors.medicamentos}
                              </span>
                            </div>
                          )}
                      </div>
                      <input
                        type="text"
                        {...cvForm.getFieldProps("medicamentos")}
                        disabled={
                          cvForm.values.toma_medicamentos_controlados === 2
                        }
                        name="medicamentos"
                        className="form-control mb-2"
                        placeholder="Ingresa los medicamentos"
                        maxLength={200}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                    <div className="w-100 pe-5 mt-5">
                      <div className="w-100 d-flex">
                        <label className="required form-label w-auto">Religión</label>
                        {/* {cvForm.touched.religion?.id && cvForm.errors.religion?.id && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">{cvForm.errors.religion.id}</span>
                          </div>
                        )} */}
                      </div>
                      <select
                        className="form-select mb-2"
                        {...cvForm.getFieldProps("religion.id")}
                      >
                        <option value={0}>Seleccione</option>
                        {religion.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                    <div className="w-100 pe-5 mt-5">
                      <div className="w-100 d-flex">
                        <label className="required form-label w-auto">
                          Crédito Infonavit
                        </label>
                        {cvForm.touched.tiene_credito_infonavit &&
                          cvForm.errors.tiene_credito_infonavit && (
                            <div className="fv-plugins-message-container text-danger w-auto ms-5">
                              <span role="alert">
                                {cvForm.errors.tiene_credito_infonavit}
                              </span>
                            </div>
                          )}
                      </div>
                      <div className="w-100 d-flex mt-3">
                        <label className="form-check-label fw-bolder text-gray-800 w-50">
                          <input
                            className="form-check-input me-3"
                            type="radio"
                            onChange={handleOninfonavitChange}
                            checked={
                              cvForm.values.tiene_credito_infonavit === true
                            }
                            value={1}
                            name="tiene_credito_infonavit"
                          />
                          Si
                        </label>
                        <label className="form-check-label fw-bolder text-gray-800 w-50">
                          <input
                            className="form-check-input me-3"
                            type="radio"
                            checked={
                              cvForm.values.tiene_credito_infonavit === false
                            }
                            onChange={handleOninfonavitChange}
                            value={2}
                            name="tiene_credito_infonavit"
                          />
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                
              </div>
              <div className="card-header " >
                <div className="card-title">
                  <h4>Domicilio</h4>
                </div>
              </div>
              <div className="card-body border-top">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                    <div className="w-100 pe-5 mt-5">
                      <div className="w-100 d-flex">
                        <label className="required form-label w-auto">
                          Código postal
                        </label>
                        {cvForm.touched.codigo_postal &&
                          cvForm.errors.codigo_postal && (
                            <div className="fv-plugins-message-container text-danger w-auto ms-5">
                              <span role="alert">
                                {cvForm.errors.codigo_postal}
                              </span>
                            </div>
                          )}
                      </div>
                      <input
                        type="text"
                        onChange={(event) => {
                          cvForm.handleChange(event);
                          setCode(parseInt(event.target.value));
                          setIndicator(1);

                        }}
                        onBlur={cvForm.handleBlur}
                        value={cvForm.values.codigo_postal}
                        name="codigo_postal"
                        className="form-control mb-2"
                        placeholder="Código postal"
                        maxLength={6}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                    <div className="w-100 pe-5 mt-5">
                      <div className="w-100 d-flex">
                        <label className="required form-label w-auto">
                          Calle y Número
                        </label>
                        {cvForm.touched.domicilio && cvForm.errors.domicilio && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">{cvForm.errors.domicilio}</span>
                          </div>
                        )}
                      </div>
                      <input
                        type="text"
                        {...cvForm.getFieldProps("domicilio")}
                        name="domicilio"
                        className="form-control mb-2"
                        placeholder="Calle y Número"
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                    <div className="w-100 pe-5 mt-5">
                      <div className="w-100 d-flex">
                        <label className="required form-label w-auto">
                          Colonia
                        </label>
                        {cvForm.touched.colonia && cvForm.errors.colonia && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">{cvForm.errors.colonia}</span>
                          </div>
                        )}
                      </div>

                      {optionColonia ? (
                        <select
                          className="form-select"
                          data-kt-select2="true"
                          data-placeholder="Select option"
                          data-allow-clear="true"
                          {...cvForm.getFieldProps('colonia')}
                          value={colonia}
                          onChange={(event) => {
                            setColonia(event.target.value);

                            if (event.target.value == "otra") {
                              setOptionColonia(false);
                              setShowBtn(true);
                              setColonia("");
                            }
                          }}
                        >
                          <option value={""}>Seleccione</option>
                          {colonias &&
                            colonias.map((item: any, index: any) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                        </select>
                      ) : (
                        <>
                          <input
                            className="form-control"
                            {...cvForm.getFieldProps("colonia")}
                            value={colonia}
                            name="colonia"
                            onChange={(event) => {
                              setColonia(event.target.value);
                              // cvForm.setFieldValue("colonia", event.target.value);
                            }}
                          />

                          {showBtn ? (
                            <a
                              className="cursor-pointer btn-ligth-primary text-primary mt-2"
                              onClick={() => {
                                setOptionColonia(true);
                                cvForm.setFieldValue("colonia", "");
                                setColonia("");
                              }}
                            >
                              Ver listado
                            </a>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                    <div className="w-100 pe-5 mt-5">
                      <div className="w-100 d-flex">
                        <label className="required form-label w-auto">
                          Municipio
                        </label>
                        {cvForm.touched.municipio && cvForm.errors.municipio && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">{cvForm.errors.municipio}</span>
                          </div>
                        )}
                      </div>
                      <input
                        type="text"
                        {...cvForm.getFieldProps("municipio")}
                        name="municipio"
                        // value={municipio}
                        className="form-control mb-2"
                        placeholder="Municipio"
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
                    <div className="w-100 pe-5 mt-5">
                      <div className="w-100 d-flex">
                        <label className="required form-label w-auto">
                          Estado
                        </label>
                        {cvForm.touched.estado && cvForm.errors.estado && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">{cvForm.errors.estado}</span>
                          </div>
                        )}
                      </div>
                      <input
                        type="text"
                        {...cvForm.getFieldProps("estado")}
                        name="estado"
                        // value={estado}
                        className="form-control mb-2"
                        placeholder="Estado"
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="w-100 pe-5 mt-5">
                      <div className="w-100 d-flex">
                        <label className="required form-label w-auto">
                          Perfil
                        </label>
                        {cvForm.touched.perfil && cvForm.errors.perfil && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">{cvForm.errors.perfil}</span>
                          </div>
                        )}
                      </div>
                      <textarea
                        className="form-control mb-2"
                        rows={8}
                        placeholder="Ejemplo de perfil:
- 3 años de experiencia en actividades de análisis, diseño, desarrollo e implementación de Sistemas de Información.
- 2 años de experiencia trabajando con Java, J2EE, Struts, Hibernate y diferentes bases de datos como Oracle y SQL.
- 1 año Soporte técnico remoto en la operación de ERP y bases de datos.
- 2 años de experiencia como líder de proyecto."
                        {...cvForm.getFieldProps("perfil")}
                        style={{ resize: "none" }}
                      ></textarea>
                      <div className="text-muted fs-7">
                        {cvForm.values.perfil.length} / 2000
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* MEDIA */}

          <div className="card card-flush py-4">
            <div className="card-header">
              <div className="card-title">
                <h4 className="required">Experiencia Profesional</h4>
                {cvForm.touched.experienciaProfesional &&
                  cvForm.errors.experienciaProfesional && (
                    <div className="fv-plugins-message-container text-danger w-auto ms-5 fs-6">
                      <span role="alert">
                        {cvForm.errors.experienciaProfesional}
                      </span>
                    </div>
                  )}
              </div>
              <div className="card-toolbar">
                <a
                  href="#/"
                  className="btn btn-sm btn-flex btn-light-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_new_card"
                  onClick={addNewWorkExp}
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen035.svg"
                    className="svg-icon-3 svg-icon-primary"
                  />
                  Agregar experiencia profesional
                </a>
              </div>
            </div>
            <div className="card-body border-top">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                    <KTSVG
                      path="/media/icons/duotune/general/gen044.svg"
                      className="svg-icon-2tx svg-icon-primary me-4"
                    />
                    <div className="d-flex flex-stack flex-grow-1">
                      <div className="fw-bold">
                        <div className="fs-6 text-gray-600">
                          Empieza con el actual o último. Si dicha experiencia
                          corresponde a la misma empresa, separar por roles y
                          fechas.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {
                  // loadingWorkExp ? (
                  //   <Preload></Preload>
                  // ) :
                  cvForm.values.experienciaProfesional.length > 0 ? (
                    <>
                      {cvForm.values.experienciaProfesional.map((row: any) => (
                        <>
                          <div className="col-12">
                            <div className="d-flex flex-stack">
                              <div className="d-flex">
                                <div className="d-flex flex-column">
                                  <a
                                    href="#/"
                                    className="fs-5 text-dark text-hover-primary fw-bolder"
                                  >
                                    {row.empresa}
                                  </a>
                                  <div className="fs-6 fw-bold text-gray-400">
                                    {row.proyecto} (
                                    {formatearFecha(
                                      new Date(row.fecha_ingreso)
                                    )}{" "}
                                    / {formatearFecha(new Date(row.fecha_fin))})
                                  </div>
                                </div>
                                {row.trabajo_actual && (
                                  <div className="d-flex flex-column">
                                    <a
                                      href="#/"
                                      className="fs-5 text-dark text-hover-primary fw-bolder ms-10"
                                    >
                                      <span className="badge badge-success">
                                        Empleo actual
                                      </span>
                                    </a>
                                  </div>
                                )}
                              </div>
                              <div className="d-flex justify-content-end">
                                <a
                                  href="#/"
                                  onClick={() => {
                                    editExp(row);
                                  }}
                                  className="btn btn-icon btn-active-light-primary w-30px h-30px me-3 tooltip-soft"
                                >
                                  <KTSVG
                                    path="/media/icons/duotune/text/txt001.svg"
                                    className="svg-icon-2 svg-icon-primary"
                                  />
                                  <span className="tooltiptext">Editar</span>
                                </a>
                                <a
                                  href="#/"
                                  className="btn btn-icon btn-active-light-primary w-30px h-30px me-3 tooltip-soft"
                                  onClick={() => {
                                    deleteExp(row);
                                  }}
                                >
                                  <KTSVG
                                    path="/media/icons/duotune/general/gen027.svg"
                                    className="svg-icon-2 svg-icon-primary"
                                  />
                                  <span className="tooltiptext">Eliminar</span>
                                </a>
                              </div>
                            </div>
                            <div className="separator separator-dashed my-5"></div>
                          </div>
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="text-center">
                        <span className="text-gray-800">Sin registros</span>
                      </div>
                    </>
                  )
                }
              </div>
            </div>
          </div>
          <div className="card card-flush py-4">
            <div className="card-header">
              <div className="card-title">
                <h4 className="required">Habilidades Técnicas</h4>
                {cvForm.touched.habilidades_tecnicas &&
                        cvForm.errors.habilidades_tecnicas && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {cvForm.errors.habilidades_tecnicas}
                            </span>
                          </div>
                        )}
              </div>
            </div>
            <div className="card-body border-top">
              <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                    <KTSVG
                      path="/media/icons/duotune/general/gen044.svg"
                      className="svg-icon-2tx svg-icon-primary me-4"
                    />
                    <div className="d-flex flex-stack flex-grow-1">
                      <div className="fw-bold">
                        <div className="fs-6 text-gray-600">
                          Esta sección te permite detallar tus habilidades en programación, diseño web, análisis de datos y más.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  <div className="w-100 pe-5 mt-5">
                    {/* <div className="w-100 d-flex">
                      <label className="required form-label w-auto">
                        Habilidades Técnicas
                      </label>
                      
                    </div> */}
                    <textarea
                      className="form-control mb-2"
                      rows={6}
                      placeholder="Descripción de las herramientas tecnológicas que manejas. Incluyen, Plataformas, Lenguajes de Programación, Metodologías, Bases de Datos, Servidores de Aplicaciones, Middleware, Lenguajes Scripts, Sistemas Operativos, ERPs, etc.)"
                      {...cvForm.getFieldProps("habilidades_tecnicas")}
                      style={{ resize: "none" }}
                    ></textarea>
                    <div className="text-muted fs-7">
                      {cvForm.values.habilidades_tecnicas
                        ? `${cvForm.values.habilidades_tecnicas.length} / 2000`
                        : "0 / 2000"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flush py-4">
            <div className="card-header">
              <div className="card-title">
                <h4 className="required">Formación Académica</h4>
                {cvForm.touched.formacionAcademica &&
                  cvForm.errors.formacionAcademica && (
                    <div className="fv-plugins-message-container text-danger w-auto ms-5 fs-6">
                      <span role="alert">
                        {cvForm.errors.formacionAcademica}
                      </span>
                    </div>
                  )}
              </div>
              <div className="card-toolbar">
                <a
                  href="#/"
                  className="btn btn-sm btn-flex btn-light-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_new_card"
                  onClick={addNewAcademic}
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen035.svg"
                    className="svg-icon-3 svg-icon-primary"
                  />
                  Agregar informacion académica
                </a>
              </div>
            </div>
            <div className="card-body border-top">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                    <KTSVG
                      path="/media/icons/duotune/general/gen044.svg"
                      className="svg-icon-2tx svg-icon-primary me-4"
                    />
                    <div className="d-flex flex-stack flex-grow-1">
                      <div className="fw-bold">
                        <div className="fs-6 text-gray-600">
                          Doctorado, Maestría, Licenciatura o Carrera Técnica.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {
                  // loadingAcademic ? (
                  //   <Preload></Preload>
                  // ) :
                  cvForm.values.formacionAcademica.length > 0 ? (
                    <>
                      {cvForm.values.formacionAcademica.map((row: any) => (
                        <>
                          <div className="col-12">
                            <div className="d-flex flex-stack">
                              <div className="d-flex">
                                <div className="d-flex flex-column">
                                  <a
                                    href="#/"
                                    className="fs-5 text-dark text-hover-primary fw-bolder"
                                  >
                                    {row.institucion}
                                  </a>
                                  <div className="fs-6 fw-bold text-gray-400">
                                    {row.carrera} (
                                    {formatearFecha(new Date(row.fecha_inicio))}{" "}
                                    / {formatearFecha(new Date(row.fecha_fin))})
                                  </div>
                                </div>
                                {row.estudio_actual && (
                                  <div className="d-flex flex-column">
                                    <a
                                      href="#/"
                                      className="fs-5 text-dark text-hover-primary fw-bolder ms-10"
                                    >
                                      <span className="badge badge-success">
                                        Estudio actual
                                      </span>
                                    </a>
                                  </div>
                                )}
                              </div>
                              <div className="d-flex justify-content-end">
                                <a
                                  href="#/"
                                  onClick={() => {
                                    editAcademic(row);
                                  }}
                                  className="btn btn-icon btn-active-light-primary w-30px h-30px me-3 tooltip-soft"
                                >
                                  <KTSVG
                                    path="/media/icons/duotune/text/txt001.svg"
                                    className="svg-icon-2 svg-icon-primary"
                                  />
                                  <span className="tooltiptext">Editar</span>
                                </a>

                                <a
                                  href="#/"
                                  className="btn btn-icon btn-active-light-primary w-30px h-30px me-3 tooltip-soft"
                                  onClick={() => {
                                    deleteAcademic(row);
                                  }}
                                >
                                  <KTSVG
                                    path="/media/icons/duotune/general/gen027.svg"
                                    className="svg-icon-2 svg-icon-primary"
                                  />
                                  <span className="tooltiptext">Eliminar</span>
                                </a>
                              </div>
                            </div>

                            <div className="separator separator-dashed my-5"></div>
                          </div>
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="text-center">
                        <span className="text-gray-800">Sin registros</span>
                      </div>
                    </>
                  )
                }
              </div>
            </div>
          </div>
          <div className="card card-flush py-4">
            <div className="card-header">
              <div className="card-title">
                <h4 className="required">Desarrollo Profesional</h4>
                {cvForm.touched.desarrolloProfresional &&
                  cvForm.errors.desarrolloProfresional && (
                    <div className="fv-plugins-message-container text-danger w-auto ms-5 fs-6">
                      <span role="alert">
                        {cvForm.errors.desarrolloProfresional}
                      </span>
                    </div>
                  )}
              </div>
              <div className="card-toolbar">
                <a
                  href="#/"
                  className="btn btn-sm btn-flex btn-light-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_new_card"
                  onClick={addNewCareer}
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen035.svg"
                    className="svg-icon-3 svg-icon-primary"
                  />
                  Agregar desarrollo profesional
                </a>
              </div>
            </div>
            <div className="card-body border-top">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                    <KTSVG
                      path="/media/icons/duotune/general/gen044.svg"
                      className="svg-icon-2tx svg-icon-primary me-4"
                    />
                    <div className="d-flex flex-stack flex-grow-1">
                      <div className="fw-bold">
                        <div className="fs-6 text-gray-600">
                          Cursos, Diplomados, Talleres, , Certificaciones.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {
                  // loadingWorkExp ? (
                  //   <Preload></Preload>
                  // ) :
                  cvForm.values.desarrolloProfresional.length > 0 ? (
                    <>
                      {cvForm.values.desarrolloProfresional.map((row: any) => (
                        <>
                          <div className="col-12">
                            <div className="d-flex flex-stack">
                              <div className="d-flex">
                                <div className="d-flex flex-column">
                                  <a
                                    href="#/"
                                    className="fs-5 text-dark text-hover-primary fw-bolder"
                                  >
                                    {row.nombre}
                                  </a>
                                  <div className="fs-6 fw-bold text-gray-400">
                                    {row.proyecto} (
                                    {formatearFecha(new Date(row.fecha_inicio))}{" "}
                                    / {formatearFecha(new Date(row.fecha_fin))})
                                  </div>
                                </div>
                                {row.en_curso && (
                                  <div className="d-flex flex-column">
                                    <a
                                      href="#/"
                                      className="fs-5 text-dark text-hover-primary fw-bolder ms-10"
                                    >
                                      <span className="badge badge-success">
                                        Curso actual
                                      </span>
                                    </a>
                                  </div>
                                )}
                              </div>
                              <div className="d-flex justify-content-end">
                                <a
                                  href="#/"
                                  onClick={() => {
                                    editCareer(row);
                                  }}
                                  className="btn btn-icon btn-active-light-primary w-30px h-30px me-3 tooltip-soft"
                                >
                                  <KTSVG
                                    path="/media/icons/duotune/text/txt001.svg"
                                    className="svg-icon-2 svg-icon-primary"
                                  />
                                  <span className="tooltiptext">Editar</span>
                                </a>

                                <a
                                  href="#/"
                                  className="btn btn-icon btn-active-light-primary w-30px h-30px me-3 tooltip-soft"
                                  onClick={() => {
                                    deleteCareer(row);
                                  }}
                                >
                                  <KTSVG
                                    path="/media/icons/duotune/general/gen027.svg"
                                    className="svg-icon-2 svg-icon-primary"
                                  />
                                  <span className="tooltiptext">Eliminar</span>
                                </a>
                              </div>
                            </div>
                            <div className="separator separator-dashed my-5"></div>
                          </div>
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="text-center">
                        <span className="text-gray-800">Sin registros</span>
                      </div>
                    </>
                  )
                }
              </div>
            </div>
          </div>
          <div className="card card-flush py-4">
            <div className="card-header">
              <div className="card-title">
                <h4>Idiomas</h4>
                {cvForm.touched.idiomas && cvForm.errors.idiomas && (
                  <div className="fv-plugins-message-container text-danger w-auto ms-5 fs-6">
                    <span role="alert">{cvForm.errors.idiomas}</span>
                  </div>
                )}
              </div>
              <div className="card-toolbar">
                <a
                  href="#/"
                  className="btn btn-sm btn-flex btn-light-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_new_card"
                  onClick={addNewLenguage}
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen035.svg"
                    className="svg-icon-3 svg-icon-primary"
                  />
                  Agregar idioma
                </a>
              </div>
            </div>
            <div className="card-body border-top">
              <div className="row">
                <div className="col-12">
                  {cvForm.values?.idiomas.length > 0 ? (
                    <div className="d-flex align-items-center">
                      {cvForm.values?.idiomas.map((row: any) => (
                        <>
                          <a
                            href="#/"
                            className="btn btn-sm btn-light-info btn-color-info btn-active-light-info px-4 py-2 me-4"
                          >
                            <span
                              onClick={() => {
                                deleteLenguage(row);
                              }}
                            >
                              <KTSVG
                                path="/media/icons/duotune/general/gen040.svg"
                                className="svg-icon-3"
                              />
                            </span>
                            {row.idioma} - {row.nivel.name}
                          </a>
                        </>
                      ))}
                    </div>
                  ) : (
                    <>
                      <div className="text-center">
                        <span className="text-gray-800">Sin registros</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flush py-4">
            <div className="card-header">
              <div className="card-title">
                <h4>Pretensión Económica</h4>
              </div>
            </div>
            <div className="card-body border-top">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                  <div className="w-100 pe-5 mt-5">
                    <div className="w-100 d-flex">
                      <label className="required form-label w-auto">
                        Sueldo actual neto
                      </label>
                      {cvForm.touched.sueldo_actual &&
                        cvForm.errors.sueldo_actual && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {cvForm.errors.sueldo_actual}
                            </span>
                          </div>
                        )}
                    </div>
                    <NumericFormat
                      name="sueldo_actual"
                      className="form-control mb-3"
                      value={cvForm.values.sueldo_actual}
                      decimalScale={2}
                      decimalSeparator="."
                      fixedDecimalScale
                      placeholder="$ 0.00"
                      prefix="$ "
                      maxLength={18}
                      allowNegative={false}
                      thousandSeparator=","
                      onValueChange={(values) => {
                        const { floatValue } = values;
                        cvForm.setFieldValue(
                          "sueldo_actual",
                          floatValue ? floatValue : 0
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                  <div className="w-100 pe-5 mt-5">
                    <div className="w-100 d-flex">
                      <label className="required form-label w-auto">
                        Expectativa económica neta
                      </label>
                      {cvForm.touched.expectativa_cambio &&
                        cvForm.errors.expectativa_cambio && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {cvForm.errors.expectativa_cambio}
                            </span>
                          </div>
                        )}
                    </div>
                    <NumericFormat
                      name="expectativa_cambio"
                      className="form-control mb-3"
                      value={cvForm.values.expectativa_cambio}
                      decimalScale={2}
                      decimalSeparator="."
                      fixedDecimalScale
                      placeholder="$ 0.00"
                      prefix="$ "
                      maxLength={18}
                      allowNegative={false}
                      thousandSeparator=","
                      onValueChange={(values) => {
                        const { floatValue } = values;
                        cvForm.setFieldValue(
                          "expectativa_cambio",
                          floatValue ? floatValue : 0
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                  <div className="w-100 pe-5 mt-5">
                    <div className="w-100 d-flex">
                      <label className="required form-label w-auto">
                        Modalidad de trabajo deseada
                      </label>
                      {cvForm.touched.modalidad?.id &&
                        cvForm.errors.modalidad?.id && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {cvForm.errors.modalidad.id}
                            </span>
                          </div>
                        )}
                    </div>
                    <select
                      className="form-select"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      {...cvForm.getFieldProps("modalidad.id")}
                    >
                      <option value={0}>Seleccione</option>
                      {modalidad.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flush py-4">
            <div className="card-header">
              <div className="card-title">
                <h4>Vacantes de Interés</h4>
                {cvForm.touched.vacantesInteres && cvForm.errors.vacantesInteres && (
                  <div className="fv-plugins-message-container text-danger w-auto ms-5 fs-6">
                    <span role="alert">{cvForm.errors.vacantesInteres}</span>
                  </div>
                )}
              </div>
              <div className="card-toolbar">
                <a
                  href="#/"
                  className="btn btn-sm btn-flex btn-light-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_new_card"
                  onClick={addNewVacante}
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen035.svg"
                    className="svg-icon-3 svg-icon-primary"
                  />
                  Agregar vacante
                </a>
              </div>
            </div>
            <div className="card-body border-top">
              <div className="row">
                <div className="col-12">
                  {cvForm.values?.vacantesInteres.length > 0 ? (
                    <div className="d-flex align-items-center">
                      {cvForm.values?.vacantesInteres.map((row: any) => (
                        <a
                        href="#/"
                        className="btn btn-sm btn-light-info btn-color-info btn-active-light-info px-4 py-2 me-4"
                      >
                        <span
                          onClick={() => {
                            deleteVacant(row);
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen040.svg"
                            className="svg-icon-3"
                          />
                        </span>
                        {row.nombre}
                      </a>
                      ))}
                    </div>
                  ) : (
                    <>
                      <div className="text-center">
                        <span className="text-gray-800">Sin registros</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          

          <div className="d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary"
              data-kt-users-modal-action="submit"
              disabled={loading}
            >
              {!loading && <span className="indicator-label">Guardar</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Espere por favor...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
      <WorkExperienceModal
        show={showWorkExpModal}
        dataEdit={dataWorkExp}
        title={titleWorkExpModal}
        handleEdit={(data: any) => {
          setLoadingWorkExp(true);
          const expExists = cvForm.values.experienciaProfesional;
          const existData = expExists.filter((item) => {
            if (item.id !== data.id) {
              return item;
            }
          });
          const orderArray = [...existData, data];
          cvForm.setFieldValue("experienciaProfesional", sortDate(orderArray));
          setDataWorkExp(initValuesWorkExp);
          setShowWorkExpModal(!showWorkExpModal);
        }}
        handleClose={(updateGrid: boolean) => {
          setDataWorkExp(initValuesWorkExp);
          setShowWorkExpModal(!showWorkExpModal);
        }}
      ></WorkExperienceModal>
      <AcademicModal
        show={showAcademicModal}
        dataEdit={dataAcademic}
        title={titleAcademicModal}
        handleEdit={(data: any) => {
          setLoadingAcademic(true);
          const academic = cvForm.values.formacionAcademica;
          const existData = academic.filter((item) => {
            if (item.id !== data.id) {
              return item;
            }
          });
          const orderArray = [...existData, data];
          cvForm.setFieldValue("formacionAcademica", sortDate(orderArray));
          setDataAcademic(initValuesAcademic);
          setAcademicModal(!AcademicModal);
        }}
        handleClose={(updateGrid: boolean) => {
          setDataAcademic(initValuesAcademic);
          setAcademicModal(!AcademicModal);
        }}
      ></AcademicModal>
      <LenguageModal
        show={showLenguageModal}
        dataEdit={dataLenguage}
        title={titleLenguageModal}
        handleEdit={(data: any) => {
          setLoadingLenguage(true);
          const lenguage = cvForm.values.idiomas;
          const orderArray = [...lenguage, data];
          cvForm.setFieldValue("idiomas", orderArray.reverse());
          setDataLenguage(initValuesLanguage);
          setLenguageModal(!LenguageModal);
        }}
        handleClose={(updateGrid: boolean) => {
          setDataLenguage(initValuesLanguage);
          setLenguageModal(!LenguageModal);
        }}
      ></LenguageModal>
      <CareerModal
        show={showCareerModal}
        dataEdit={dataCareer}
        title={titleCareerModal}
        handleEdit={(data: any) => {
          setLoadingAcademic(true);
          const career = cvForm.values.desarrolloProfresional;
          const existData = career.filter((item) => {
            if (item.id !== data.id) {
              return item;
            }
          });
          const orderArray = [...existData, data];
          cvForm.setFieldValue("desarrolloProfresional", sortDate(orderArray));
          setDataCareer(initValuesCareer);
          setCareerModal(!CareerModal);
        }}
        handleClose={(updateGrid: boolean) => {
          setDataAcademic(initValuesAcademic);
          setAcademicModal(!CareerModal);
        }}
      ></CareerModal>
      <VacantModal
        show={showVacantModal}
        dataEdit={dataVacant}
        title={titleVacantModal}
        handleEdit={(data: any) => {
          setLoadingVacant(true);
          const vacant = cvForm.values.vacantesInteres;
          const orderArray = [...vacant, data];
          cvForm.setFieldValue("vacantesInteres", orderArray.reverse());
          setDataVacant(initValuesVacant);
          setVacantModal(!VacantModal);
        }}
        handleClose={(updateGrid: boolean) => {
          setDataVacant(initValuesVacant);
          setVacantModal(!VacantModal);
        }}
      ></VacantModal>
    </>
  );
};

export { View };
