

  export const sortDate = ( array : any[] ) => {
    array.sort((a: any, b: any) => {
        const fechaFinA = new Date(a.fecha_fin).getTime();
        const fechaFinB = new Date(b.fecha_fin).getTime();
    
        if (!isNaN(fechaFinA) && !isNaN(fechaFinB)) {
          return fechaFinB - fechaFinA;
        } else {
          return 0;
        }
      })
   return array;
}