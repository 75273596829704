import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { KTSVG } from "../../../../rbts/helpers";
import * as Yup from "yup";

type Props = {
  initFilterValues: any;
  onCleanFilter: () => void;
  onResetFilter: () => void;
  onSearchFilter: (values: any) => void;
};

const Filter: React.FC<Props> = ({
  initFilterValues,
  onSearchFilter,
  onCleanFilter,
  onResetFilter
}) => {

  const validationSchema = Yup.object().shape({
    edad_minima: Yup.number()
      .test(
        "is-not-negative",
        "Edad negativa",
        (value) => value === undefined || value >= 0
      )
      .min(16, "Menor de edad")
      .max(99, "La edad máxima permitida es 99 años"),
    edad_maxima: Yup.number()
      .test(
        "is-not-negative",
        "Edad negativa",
        (value) => value === undefined || value >= 0
      )
      .min(16, "Menor de edad")
      .max(99, "La edad máxima permitida es 99 años"),
  });

  const formFilter = useFormik({
    initialValues: initFilterValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      await onSearchFilter(values);
    },
  });

  useEffect(() => {
    if (initFilterValues) {
      formFilter.setValues(initFilterValues);
    }
  }, [initFilterValues]);


  return (
    <>
      <div className="col-xl-12">
        <div className="card pt-4 mb-6 mb-xl-9">
          <div className="card-header min-h-auto">
            <h3 className="card-title text-gray-900 fw-bold fs-3 mb-5">
              Filtros
            </h3>
          </div>
          <div className="card-body">
            <form
              style={{ width: "100%" }}
              id="kt_modal_generic_form"
              className="form"
              action="#"
              onSubmit={formFilter.handleSubmit}
            >
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mt-3">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">
                      Perfil ej. Desarrollador, Analista
                    </label>
                  </div>
                  <div className={"position-relative"}>
                    <input
                      type="text"
                      placeholder="Buscar candidato"
                      onChange={(e) => {
                        formFilter.setFieldValue("perfil", e.target.value);
                      }}
                      className={"form-control form-control-lg"}
                    />
                    <span
                      className={
                        "btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                      }
                    >
                      <KTSVG
                        path="/media/icons/duotune/general/gen004.svg"
                        className="svg-icon-2 svg-icon-gray-500"
                      />
                    </span>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3 mt-3">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">
                      Herramientas ej. C#, MySQL
                    </label>
                  </div>
                  <div className={"position-relative"}>
                    <input
                      type="text"
                      placeholder="Buscar candidato"
                      onChange={(e) => {

                        formFilter.setFieldValue(
                          "herramientas",
                          e.target.value
                        );

                      }}
                      className={"form-control form-control-lg"}
                    />
                    <span
                      className={
                        "btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                      }
                    >
                      <KTSVG
                        path="/media/icons/duotune/general/gen004.svg"
                        className="svg-icon-2 svg-icon-gray-500"
                      />
                    </span>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3 mt-3">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">
                      Actividades ej. Desarrollo de software
                    </label>
                  </div>
                  <div className={"position-relative"}>
                    <input
                      type="text"
                      placeholder="Buscar candidato"
                      onChange={(e) => {
                        formFilter.setFieldValue(
                          "actividades",
                          e.target.value
                        );
                      }}
                      className={"form-control form-control-lg"}
                    />
                    <span
                      className={
                        "btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                      }
                    >
                      <KTSVG
                        path="/media/icons/duotune/general/gen004.svg"
                        className="svg-icon-2 svg-icon-gray-500"
                      />
                    </span>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-1 mt-3">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">Edad mínima</label>
                  </div>
                  <div className={"position-relative"}>
                    <input
                      type="number"
                      placeholder="Mínimo"
                      className={`form-control form-control-lg ${formFilter.touched.edad_minima &&
                        formFilter.errors.edad_minima
                        ? "is-invalid"
                        : ""
                        }`}
                      {...formFilter.getFieldProps("edad_minima")}
                      onChange={(e) => {
                        formFilter.handleChange(e);
                        formFilter.setFieldTouched("edad_minima", true, false);
                      }}
                    />
                    {formFilter.touched.edad_minima &&
                      formFilter.errors.edad_minima && (
                        <div className="invalid-feedback">
                          {formFilter.errors.edad_minima}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-1 mt-3">
                  <div className="w-100 d-flex">
                    <label className="form-label w-auto">Edad máxima</label>
                  </div>
                  <div className={"position-relative"}>
                    <input
                      type="number"
                      placeholder="Máximo"
                      className={`form-control form-control-lg ${formFilter.touched.edad_maxima &&
                        formFilter.errors.edad_maxima
                        ? "is-invalid"
                        : ""
                        }`}
                      {...formFilter.getFieldProps("edad_maxima")}
                      onChange={(e) => {
                        formFilter.handleChange(e);
                        formFilter.setFieldTouched("edad_maxima", true, false);
                      }}
                    />
                    {formFilter.touched.edad_maxima &&
                      formFilter.errors.edad_maxima && (
                        <div className="invalid-feedback">
                          {formFilter.errors.edad_maxima}
                        </div>
                      )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex justify-content-end mt-10">
                      <button
                        type="reset"
                        className="btn btn-sm btn-white btn-active-light-primary me-2"
                        data-kt-menu-dismiss="true"
                        onClick={() => {
                          formFilter.resetForm();
                          onCleanFilter();
                        }}
                      >
                        Limpiar
                      </button>
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        data-kt-menu-dismiss="true"
                      >
                        Buscar
                      </button>

                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export { Filter };