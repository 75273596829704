import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getCVCandidates } from '../../../service/candidates/Candidates';
import { CVCandidateModel } from '../models/CandidateCV';

export const useGetAllCVCandidates = (realoadGrid: number, startIndex: number, endIndex: number, filters: any) => {

    const [data, setData] = useState<CVCandidateModel[]>([]);
    const [count, setCount] = useState(1);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const history = useHistory()

    useEffect(() => {
        const fetchPost = async () => {
            setLoadingRequest(0)
            const res = await getCVCandidates(startIndex, endIndex, filters).then().catch((error) => {
                history.push('/error/500')
            });
            if (res) {
                setData(res.rows);
                setCount(res.count);
                setLoadingRequest(1);
            }
        };
        if (realoadGrid) {
            fetchPost();
        }
    }, [endIndex, realoadGrid]);
    return { data, count, loadingRequest }
}