import React from "react";
import { useGetOne } from "../hooks/CandidatesHook";
import {
  toAbsoluteUrl,
  formatPrice,
  formatearFecha,
} from "../../../../../rbts/helpers";
import axios from "axios";

type Props = {
  id: number;
};

const View: React.FC<Props> = ({ id }) => {
  const { initValues, loadingRequest } = useGetOne(id);

  const downloadFile = (fileName: string) => {
    axios({
      url: `${process.env.REACT_APP_API}candidatos/documentos/download/`,
      method: "POST",
      data: {
        nombreArchivo: fileName,
      },
      responseType: "blob",
    })
      .then((response) => {
        const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = urlBlob;
        link.setAttribute("download", fileName);
        link.click();
        window.URL.revokeObjectURL(urlBlob);
      })
      .catch(async (error) => {
        let responseObj = JSON.parse(await error.response.data.text());
      });
  };

  return (
    <>
      <div className="d-flex flex-column flex-xl-row">
        <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
          <div className="card mb-5 mb-xl-8">
            <div className="card-body pt-15">
              <div className="d-flex flex-center flex-column mb-5">
                <div className="symbol symbol-100px  mb-7">
                  <img
                    src={
                      initValues.user?.profile_photo
                        ? `${process.env.REACT_APP_PUBLIC}users/${initValues.user?.profile_photo}`
                        : toAbsoluteUrl("/media/avatars/blank.png")
                    }
                    crossOrigin="anonymous"
                    className=""
                    alt=""
                  />
                </div>
                <a
                  href="#"
                  className="fs-3 text-gray-800 text-hover-primary fw-bold mb-1 mb-3"
                >
                  {initValues.user?.first_name}{" "}
                  {initValues.user?.father_last_name}
                </a>

                {/* <div className="fs-5 fw-semibold text-muted mb-6">
                  Software Enginer{' '}
                </div> */}

                <div className="d-flex ">
                  {/* <div className="d-flex flex-wrap flex-center"> */}
                  <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3 text-center">
                    <div className="fs-5 fw-bold text-primary">
                      <span className="w-75px">
                        {formatPrice(
                          Number(initValues.sueldo_actual),
                          "es-MX",
                          "MXN",
                          2,
                          2
                        )}
                      </span>
                    </div>
                    <div className="fw-semibold text-muted" >Sueldo actual</div>
                  </div>

                  <div className="border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3 text-center" >
                    <div className="fs-5 fw-bold text-primary">
                      <span className="w-50px">
                        {formatPrice(
                          Number(initValues.expectativa_cambio),
                          "es-MX",
                          "MXN",
                          2,
                          2
                        )}
                      </span>
                    </div>
                    <div className="fw-semibold text-muted ">
                      Expectativa económica
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-stack fs-4 py-3">
                <div className="fw-bold rotate collapsible">
                  Datos Personales
                </div>
              </div>

              <div className="separator separator-dashed my-3"></div>

              <div id="kt_customer_view_details" className="collapse show">
                <div className=" fs-6">
                  <div
                    className="fw-bold mt-5"
                    onClick={() => {
                      alert("edad" + 1);
                    }}
                  >
                    Edad
                  </div>
                  <div className="text-gray-600">{initValues.edad} años</div>
                  <div className="fw-bold mt-5">Estado civil</div>
                  <div className="text-gray-600">
                    {initValues.estadoCivil.name}
                  </div>
                  <div className="fw-bold mt-5">Género</div>
                  <div className="text-gray-600">{initValues.genero.name}</div>
                  <div className="fw-bold mt-5">Religión</div>
                  <div className="text-gray-600">
                    {initValues.religion ? initValues.religion?.name : "N/A"}
                  </div>
                  <div className="fw-bold mt-5">Fecha de nacimiento</div>
                  <div className="text-gray-600">
                    {formatearFecha(new Date(initValues.fecha_nacimiento))}
                  </div>
                  <div className="fw-bold mt-5">Lugar de nacimiento</div>
                  <div className="text-gray-600">
                    {initValues.lugar_nacimiento}
                  </div>

                  <div className="fw-bold mt-5">Teléfono particular</div>
                  <div className="text-gray-600">
                    {initValues.telefono_particular}
                  </div>
                  <div className="fw-bold mt-5">Celular</div>
                  <div className="text-gray-600">{initValues.movil}</div>
                  <div className="fw-bold mt-5">Medicamentos</div>
                  <div className="text-gray-600">
                    {initValues.medicamentos ? initValues.medicamentos : "N/A"}
                  </div>
                  <div className="fw-bold mt-5">Crédito Infonavit</div>
                  <div className="text-gray-600">
                    {initValues.tiene_credito_infonavit ? "Si" : "No"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-5 mb-xl-8">
            <div className="card-body pt-4">
              <div className="d-flex flex-stack fs-4 py-3 mt-5">
                <div className="fw-bold rotate collapsible">Domicilio</div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="separator separator-dashed my-3"></div>
                  <div className="fw-bold mt-5">Código postal</div>
                  <div className="text-gray-600">
                    {initValues.codigo_postal}
                  </div>
                  <div className="fw-bold mt-5">Calle y número</div>
                  <div className="text-gray-600">{initValues.domicilio}</div>
                  <div className="fw-bold mt-5">Colonia</div>
                  <div className="text-gray-600">{initValues.colonia}</div>
                  <div className="fw-bold mt-5">Estado</div>
                  <div className="text-gray-600">{initValues.estado}</div>
                </div>
                <div className="col-md-12">

                  <div className="fw-bold mt-5">Municipio</div>
                  <div className="text-gray-600">{initValues.municipio}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-lg-row-fluid ms-lg-15">
          <div className="card pt-4 mb-6 mb-xl-9">
            <div className="card-header border-0">
              <div className="card-title">
                <h2>Perfil</h2>
              </div>
            </div>

            <div className="card-body pt-0 pb-5 text-gray-800">
              <div className="row">
                <div className="col-12">{initValues.perfil}</div>
                <div className="col-12 mt-5 mb-5">
                  <div className="separator separator-dashed my-3"></div>
                  <div className="fw-bold">
                    Idiomas
                  </div>
                  {initValues.idiomas.map((row: any) => (
                    <>
                      <a
                        href="#/"
                        className="badge badge-light-primary  px-4 py-2 me-4 mt-4"
                      >
                        {row.idioma} - {row.nivel.name}
                      </a>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="card pt-4 mb-6 mb-xl-9">
            <div className="card-header border-0">
              <div className="card-title">
                <h2>Vacantes de interés</h2>
              </div>
            </div>
            {initValues.vacantesInteres.length ? (
              <div className="card-body pt-0 pb-0">
                <div className="row justify-content-center">
                  <div className="col-12 mb-5">

                    {initValues.vacantesInteres.map(
                      (row: any, index: number) => (
                        <>
                          <a
                            href="#/"
                            className="badge badge-light-primary  px-4 py-2 me-4 "
                          >
                            {row.nombre}
                          </a>
                        </>
                      )
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-primary text-center mb-5">No hay registros</div>
            )}
          </div>
          <div className="card pt-4 mb-6 mb-xl-9">
            <div className="card-header border-0">
              <div className="card-title">
                <h2>Experiencia Profesional</h2>
              </div>
            </div>
            <div className="card-body pt-0 pb-5">
              {initValues.experienciaProfesional.map((row: any) => (
                <div className="row" key={row.id}>
                  <div className="py-3 d-flex flex-stack flex-wrap">
                    <div className="d-flex align-items-center collapsible rotate active">
                      <div className="me-3">
                        <div className="d-flex align-items-center">
                          <div className="text-gray-800 fw-bold">
                            {row.empresa}{" "}
                          </div>

                          <div className="badge badge-light-primary ms-5">
                            {row.trabajo_actual ? " Trabajo actual" : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap py-5">
                    <div className="flex-equal me-5">
                      <table className="table table-flush fw-semibold gy-1">
                        <tbody>
                          <tr>
                            <td className="text-muted min-w-200px w-200px">
                              Asignado a:
                            </td>
                            <td className="text-gray-800">{row.asignado_a}</td>
                          </tr>
                          <tr>
                            <td className="text-muted min-w-200px w-200px">
                              Teléfono consultoría:
                            </td>
                            <td className="text-gray-800">
                              {row.telefono_consultoria}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted min-w-200px w-200px">
                              Teléfono asignación:
                            </td>
                            <td className="text-gray-800">
                              {row.telefono_asignacion}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted min-w-200px w-200px">
                              Fecha ingreso:
                            </td>
                            <td className="text-gray-800">
                              {formatearFecha(new Date(row.fecha_ingreso))}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted min-w-200px w-200px">
                              Fecha fin:
                            </td>
                            <td className="text-gray-800">
                              {row.trabajo_actual
                                ? "N/A"
                                : formatearFecha(new Date(row.fecha_fin))}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted min-w-200px w-200px">
                              Proyecto o área:
                            </td>
                            <td className="text-gray-800">{row.proyecto}</td>
                          </tr>
                          <tr>
                            <td className="text-muted min-w-200px w-200px">
                              Rol:
                            </td>
                            <td className="text-gray-800">{row?.rol}</td>
                          </tr>
                          <tr>
                            <td className="text-muted min-w-200px w-200px">
                              Nombre jefe inmediato:
                            </td>
                            <td className="text-gray-800">{row.nombre_jefe}</td>
                          </tr>
                          <tr>
                            <td className="text-muted min-w-200px w-200px">
                              Puesto jefe inmediato:
                            </td>
                            <td className="text-gray-800">{row.puesto_jefe}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="flex-equal ">
                      <table className="table table-flush fw-semibold gy-1">
                        <tbody>
                          <tr>
                            <td className="text-muted min-w-200px w-200px">
                              Sueldo actual:
                            </td>
                            <td className="text-gray-800">
                              {formatPrice(
                                Number(row.sueldo_actual),
                                "es-MX",
                                "MXN",
                                2,
                                2
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted min-w-200px w-200px">
                              Motivo de salida:
                            </td>
                            <td className="text-gray-800">
                              {row.motivo_salida ? row.motivo_salida : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted min-w-200px w-200px">
                              Actividades:
                            </td>
                            <td className="text-gray-800">
                              {row.actividades}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted min-w-200px w-200px">
                              Herramientas del proyecto:
                            </td>
                            <td className="text-gray-800">
                              {row.herramientas_proyecto}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted min-w-200px w-200px">
                              Prestaciones:
                            </td>
                            <td className="text-gray-800">
                              {row.prestaciones}{" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="separator separator-dashed"></div>
                </div>
              ))}
            </div>
          </div>

          <div className="card pt-4 mb-6 mb-xl-9">
            <div className="card-header border-0">
              <div className="card-title">
                <h2>Desarrollo Profesional</h2>
              </div>
            </div>
            <div className="card-body pt-0 pb-5">
              {initValues.desarrolloProfresional.map((row: any) => (
                <div className="row" key={row.id}>
                  <div className="py-3 d-flex flex-stack flex-wrap">
                    <div className="d-flex align-items-center collapsible rotate active">
                      <div className="me-3">
                        <div className="d-flex align-items-center">
                          <div className="text-gray-800 fw-bold">
                            {row.nombre}{" "}
                          </div>

                          <div className="badge badge-light-primary ms-5">
                            {row.desarrollo.name}
                          </div>
                        </div>
                        <div className="text-muted mt-3 mb-3">
                          {formatearFecha(new Date(row.fecha_inicio))} al{" "}
                          {formatearFecha(new Date(row.fecha_fin))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed"></div>
                </div>
              ))}
            </div>
          </div>
          <div className="card pt-4 mb-6 mb-xl-9">
            <div className="card-header border-0">
              <div className="card-title">
                <h2>Habilidades Técnicas</h2>
              </div>
            </div>
            <div className="card-body pt-0 pb-5">
              <div className="row">
                <div className="py-3 d-flex flex-stack flex-wrap">
                  <div className="d-flex align-items-center collapsible rotate active">
                    <div className="me-3">
                      <div className="d-flex align-items-center">
                        <div className="text-gray-800 fw-bold"></div>
                        <div className="badge badge-light-success ms-5"></div>
                      </div>
                      <div className="text-muted mt-3 mb-3">
                        {initValues?.habilidades_tecnicas}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card pt-4 mb-6 mb-xl-9">
            <div className="card-header border-0">
              <div className="card-title">
                <h2>Formación Académica</h2>
              </div>
            </div>
            <div className="card-body pt-0 pb-5">
              {initValues.formacionAcademica.map((row: any) => (
                <div className="row" key={row.id}>
                  <div className="py-3 d-flex flex-stack flex-wrap">
                    <div className="d-flex align-items-center collapsible rotate active">
                      <div className="me-3">
                        <div className="d-flex align-items-center">
                          <div className="text-gray-800 fw-bold">
                            {row.institucion}{" "}
                          </div>

                          <div className="badge badge-light-primary ms-5">
                            {row.carrera}
                          </div>
                        </div>
                        <div className="text-muted mt-3 mb-3">
                          {formatearFecha(new Date(row.fecha_inicio))} al{" "}
                          {formatearFecha(new Date(row.fecha_fin))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed"></div>
                </div>
              ))}
            </div>
          </div>
          <div className="card pt-4 mb-6 mb-xl-9">
            <div className="card-header border-0">
              <div className="card-title">
                <h2>Documentos</h2>
              </div>
            </div>
            <div className="card-body">
              <div className="row ">
                {initValues.documentos?.length ? (
                  initValues.documentos?.map((row: any) => (
                    <div
                      className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3
                  text-gray-800 text-hover-primary mt-2 text-center"
                    >
                      <div className="symbol symbol-70px mb-6 ">
                        <img
                          src={toAbsoluteUrl("/media/svg/files/download.svg")}
                          className="image-input-wrapper cursor-pointer text-hover-primary "
                          onClick={() => {
                            downloadFile(row.nombre);
                          }}
                          crossOrigin="anonymous"
                          alt="INE"
                        />
                      </div>

                      <div className="d-flex">
                        <a
                          href="#/"
                          className="fs-6 mb-2 flex-column cursor-pointer text-gray-700 text-hover-primary text-center w-100"
                          onClick={() => {
                            downloadFile(row.nombre);
                          }}
                        >
                          {row.nombre}
                        </a>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-primary text-center">
                    No ha cargado ningún documento
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
