import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../rbts/helpers';
import { KTSVG } from '../../../../rbts/helpers';
import { useGetDocumentsCandidates } from '../../candidates_doc/hooks/DocumentsCandidatesHook';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { userHasRole } from '../../../../rbts/helpers';

const View: React.FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;
  const history = useHistory();
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const { count } = useGetDocumentsCandidates(reloadGrid);

  return (
    <>

      <div className="card " style={{ minHeight: '700px' }}>
        <div className="card-body p-0" >
          {/* <div className="col-xl-12">
            <div className="card card-xl-stretch mb-5 mb-xl-8">
              <div className="card-header border-0">
                <h3 className="card-title fw-bold text-gray-900">Documentos CV</h3>
              </div>

              <div className="card-body pt-0">
                <div className="d-flex align-items-center bg-light-success rounded p-5 mb-7">
                  <div className="symbol symbol-35px" style={{ marginRight: '15px' }}>
                    <img src="/media/svg/files/pdf.svg" />
                  </div>
                  <div className="flex-grow-1 me-2">
                    <a href="#" className="fw-bold text-gray-800 text-hover-primary fs-6">Navigation optimization</a>
                    <span className="text-muted fw-semibold d-block">230kb</span>
                  </div>
                  <span className="fw-bold text-success py-1">Completado</span>
                </div>
              </div>
            </div>
          </div> */}


          {userHasRole(['CDTO'], userRoles) && (
            <div className="card-px text-center py-20 my-10">
              <h2 className="fs-2x fw-bold mb-10">¡Bienvenido!</h2>
              <p className="text-gray-500 fs-4 fw-semibold mb-10">
                Para brindarte la mejor experiencia posible, solicitamos que
                completes tu CV. Esta información nos ayudará a personalizar tu
                perfil y asegurar que obtengas las mejores oportunidades. Por
                favor, da click en el siguiente botón para llenar tu CV. ¡Gracias
                por tu colaboración!
              </p>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <a
                  href="#/"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  onClick={() => {
                    history.push('/candidato_cv/');
                  }}
                >
                  Completar mi CV
                </a>
                <div style={{ margin: '2px' }}></div>
                <a
                  style={{ display: count > 0 ? 'block' : 'none' }}
                  href="#/"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  onClick={() => {
                    history.push('/documents/cv/');
                  }}
                >
                  Cargar documentos CV
                </a>
              </div>
            </div>
          )}
          {userHasRole(['ADMIN', 'ADMIN_COMPANY', 'RRHH'], userRoles) && (
            <div className="card-px text-center py-20 my-10">
              <h2 className="fs-2x fw-bold mb-10">¡Bienvenido!</h2>
              <p className="text-gray-500 fs-4 fw-semibold mb-10">
                Aquí encontrarás los perfiles que requieras para tu empresa.
              </p>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <a
                  href="#/"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  onClick={() => {
                    history.push('/cv/candidates');
                  }}
                >
                  Busqueda de CV
                </a>
              </div>
            </div>
          )}

          <div className="text-center px-4">
            <img
              className="mw-100 mh-300px"
              src={toAbsoluteUrl('/media/illustrations/sketchy-1/2.png')}
              alt="CV"
            />
          </div>
        </div>
      </div>



    </>
  );
};

export { View };
