import { useState, useEffect } from 'react';
import { candidateGet } from '../../../../service/candidates/Candidates';
import { CandidateModel } from '../models/CandidateModel';
import { useHistory } from 'react-router-dom';
import { getInformationByCode } from '../../../../service/candidates/Candidates';


export const useGetOne = ( random: number ) => {

  const initModel = {
    id: 0,
    edad: 18,
    fecha_nacimiento: new Date().toISOString().split('T')[0],
    religion: {
      id: 0,
      name: '',
      code: '',
    },
    lugar_nacimiento: '',
    domicilio: '',
    telefono_particular: '',
    telefono_particular_format: '',
    movil: '',
    movil_format: '',
    toma_medicamentos_controlados: 2,
    medicamentos: '',
    perfil: '',
    sueldo_actual: '',
    expectativa_cambio: '',
    tiene_credito_infonavit: false,
    colonia: '',
    municipio: '',
    estado: '',
    codigo_postal: '',
    modalidad: {
      id: 0,
      name: '',
      code: '',
    },
    estadoCivil: {
      id: 0,
      name: '',
      code: '',
    },
    genero: {
      id: 0,
      name: '',
      code: '',
    },
    habilidades_tecnicas: '',
    experienciaProfesional: [],
    formacionAcademica: [],
    idiomas: [],
    desarrolloProfresional: [],
    vacantesInteres: [],
    documentos:[]
  };
  const [loadingRequest, setLoadingRequest] = useState(0);
  const [initValues, setInitValues] = useState<CandidateModel>(initModel);
  const history = useHistory();
  useEffect(() => {
    
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await candidateGet()
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setLoadingRequest(1);
        setInitValues(res);
      }else{
        setLoadingRequest(1);
        setInitValues(initModel);
      }
    };
    if(random){
      fetchPost();

    }else{
      setInitValues(initModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [random]);
  return { initValues, loadingRequest };
};

export const useGetInformationByCode = (code: number, indicator: boolean) => {

  const [dataLocation, setDataLocation] = useState<any>([]);
  const history = useHistory();
  useEffect(() => {
      const fetchPost = async () => {
          const res = await getInformationByCode(code)
              .then()
              .catch((error) => {
                  history.push('/error/500');
              });
          if (res) {
              setDataLocation(res.data);
          }
      };

      if (indicator && code > 0) {
          fetchPost();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, indicator]);

  return { dataLocation };

}