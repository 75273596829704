import React, { useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG } from '../../../../../rbts/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelectGeneric } from '../../../catalogos/generico/hooks/genericoHook';

type Props = {
  show: boolean;
  dataEdit: any;
  title: string;
  handleClose: (updateGrid: boolean) => void;
  handleEdit: (data: boolean) => void;
};
const dataLenguageSchema = Yup.object().shape({
  idioma: Yup.string().required('Requerido.'),
  nivel: Yup.object({
    id: Yup.number().min(1, 'Requerido').required('Requerido.'),
  }),
});

const LenguageModal: React.FC<Props> = ({
  show,
  dataEdit,
  title,
  handleClose,
  handleEdit,
}) => {
  const [loading, setLoading] = useState(false);
  const { catalog: niveles } = useSelectGeneric('IDMN');

  const LenguageForm = useFormik({
    initialValues: dataEdit,
    validationSchema: dataLenguageSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        values.id = values.id === 0 ? null : values.id;
        handleEdit(values);
        LenguageForm.resetForm();
        setLoading(false);
      }, 1000);
    },
  });

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value;
    const name = e.target.options[e.target.selectedIndex].getAttribute('name');
    const code = e.target.options[e.target.selectedIndex].getAttribute('code');
    LenguageForm.setFieldValue('nivel', {
      id: id,
      name: name,
      code: code,
    });
  };

  return (
    <Modal
      id="kt_modal_work"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-lg"
      show={show}
      onHide={() => {
        LenguageForm.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header " id="kt_modal_work_header">
          <h2> {title} </h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              LenguageForm.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5">
          <form
            id="kt_modal_detail_form"
            className="form"
            action="#"
            onSubmit={LenguageForm.handleSubmit}
          >
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto required">
                      Idioma
                    </label>
                    {LenguageForm.touched.idioma && LenguageForm.errors.idioma && (
                      <div className="fv-plugins-message-container text-danger w-100 ms-5">
                        <span role="alert">{LenguageForm.errors.idioma}</span>
                      </div>
                    )}
                  </div>
                  <input
                    className="form-control mb-3"
                    type="text"
                    placeholder="Idioma"
                    {...LenguageForm.getFieldProps('idioma')}
                  ></input>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="w-100 pe-5">
                  <div className="w-100 d-flex">
                    <label className="fw-bold fs-6 mb-2 w-auto required">
                      Nivel
                    </label>
                  </div>
                  <select
                    className="form-select"
                    onChange={handleOnChange}
                    // {...LenguageForm.getFieldProps('nivel.id')}
                  >
                    <option
                      value={0}
                      {...{
                        name: '',
                        code: '',
                      }}
                    >
                      Seleccione
                    </option>
                    {niveles.map((item, index) => (
                      <option
                        key={index}
                        value={item.id}
                        {...{
                          name: item.name.toString(),
                          code: item.code.toString(),
                        }}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </form>

          <div className="text-center pt-8">
            <button
              type="reset"
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => {
                LenguageForm.resetForm();
                handleClose(false);
              }}
            >
              Cancelar
            </button>
            <button
              type="button"
              onClick={() => {
                LenguageForm.submitForm();
              }}
              className="btn btn-primary"
              data-kt-users-modal-action="submit"
              disabled={loading}
            >
              {!loading && <span className="indicator-label">Guardar</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: 'block' }}
                >
                  Espere por favor...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { LenguageModal };
