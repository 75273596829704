import React from 'react';
import { Link } from 'react-router-dom';
import { Pagination } from '../../../../rbts/helpers';
import { MenuComponent } from '../../../../rbts/assets/ts/components';
import { Preload } from '../../../../rbts/helpers';
import { currencyFormatMX } from '../../../../rbts/helpers/FormattElements';
import { KTSVG, toAbsoluteUrl } from '../../../../rbts/helpers';
import { useHistory } from 'react-router-dom';

type Props = {
  data: any;
  count: number;
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  loadingGrid: boolean;
  reset: any;
};

const List: React.FC<Props> = ({
  data,
  count,
  currentPage,
  totalPages,
  onPageChange,
  loadingGrid,
  reset,
}) => {
  const history = useHistory();

  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  return (
    <>
      {loadingGrid ? (
        <Preload />
      ) : count <= 0 ? (
        <div className="d-flex align-items-center">
          <div className="text-muted text-center fs-6 w-100 m-5">Sin datos</div>
        </div>
      ) : (
        data.map((item: any) => {
          return (
            <div className="col-md-6 col-xxl-4" key={item.id}>
              <div className="card cursor-pointer border-hover-primary " title='Ver detalle' onClick={() => {
                history.push('/candidato_detalle/' + item.id);
              }}>
                <div className="card-body d-flex flex-center flex-column pt-8 p-9">
                  <div className="card-toolbar" style={{ width: '100%' }}>
                    <div className="d-flex mb- mt-2" style={{ justifyContent: 'end' }}>
                      {/* <div title='Ver detalle' >
                        <Link to={'/candidato_detalle/' + item.id} className='btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary'>
                          <KTSVG
                            path="/media/icons/duotune/general/gen045.svg"
                            className="svg-icon-5 svg-icon-gray-500 me-1"
                          />
                        </Link>
                      </div> */}
                      {/* <div>
                        <a
                          type="button"
                          className="btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary"
                          title="Contacto"
                        >
                          <KTSVG
                            path="/media/icons/duotune/communication/com005.svg"
                            className="svg-icon-5 svg-icon-gray-500 me-1"
                          />
                        </a>
                      </div> */}
                    </div>
                  </div>
                  <div className="symbol symbol-65px symbol-circle mb-5" onClick={() => {
                    history.push('/candidato_detalle/' + item.id);
                  }}>
                    {
                      item.user?.profile_photo ? <img
                        src={`${process.env.REACT_APP_PUBLIC}users/${item.user?.profile_photo}`
                          // item.user?.profile_photo
                          //   ? `${process.env.REACT_APP_PUBLIC}users/${item.user?.profile_photo}`
                          //   : toAbsoluteUrl('/media/avatars/blank.png')
                        }
                        crossOrigin="anonymous"
                        className=""
                        alt=""
                      /> : <span className="symbol-label fs-2x fw-semibold text-primary bg-light-primary">
                        {item.user.first_name[0]}
                      </span>
                    }

                    <div className=" position-absolute border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>{' '}
                  </div>

                  <a
                    href="#"
                    className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0"
                  >
                    {item.user.first_name}
                  </a>
                  <div className='text-center'>
                    <span className={`badge badge-light-primary fw-bold me-auto px-4 py-1`}>{item.user.email}</span>
                  </div>
                  <div className="fw-semibold text-gray-500 mb-6 text-truncate mt-3">
                    {item.perfil}
                  </div>
                  <div className="d-flex flex-center flex-wrap">
                    <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                      <div className="fs-6 fw-bold text-gray-700 text-center">
                        {item.edad}
                      </div>
                      <div className="fw-semibold text-gray-500 text-center">Edad</div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                      <div className="fs-6 fw-bold text-gray-700 text-primary">
                        {currencyFormatMX(item.sueldo_actual)}
                      </div>
                      <div className="fw-semibold text-gray-500">
                        Sueldo actual
                      </div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                      <div className="fs-6 fw-bold text-gray-700 text-primary">
                        {currencyFormatMX(item.expectativa_cambio)}
                      </div>
                      <div className="fw-semibold text-gray-500">
                        Exp. cambio
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          // </div>
        })
      )}

      <div className="col-md-12">
        <div className="d-flex flex-stack flex-wrap pt-10">
          <div className="fs-6 text-muted">Total de registros: {count}</div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            reset={reset}
          ></Pagination>
        </div>
      </div>
    </>
  );
};

export { List };
