import axios from 'axios'
const API_URL = process.env.REACT_APP_API

export async function getCatalog( code:string ) {

    const response = await axios.get(`${API_URL}catalogs/${code}?sort=order`);
    const data = response.data.doc.data;
    return data;

}
export async function getCatalogGrid( code:string, filters:any  ) {

    let search : string = ''; 
    if( filters ){
        search = `?nameOpLike=%${filters}%`;
    }
    const response = await axios.get(`${API_URL}catalogs/${ code }${search}`);
    const data = response.data.doc.data;
    return data;

}
export async function addValueCatalog( code_catalogo:string, body:any ) {

    const { code, name, order } = body;
    const data = {
        code: code,
        name: name,
        order: order,
    }
    const response = await axios.post(`${API_URL}catalogs/${ code_catalogo }`, data);
    return response;

}
export async function removeRegisterCatalog( id:number ) {

    const response = await axios.delete(`${API_URL}catalogs/${id}`);
    return response;

}