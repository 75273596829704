import React from 'react';

const Preload: React.FC = () => {
  return (
    <>
      <div className="d-flex flex-column flex-grow-1">
        <div className="text-center">
          <span
            className="indicator-progress text-primary"
            style={{ display: 'block' }}
          >
            <div className="text-center mt-3">
              <span className="spinner-border spinner-border-lg align-middle ms-2"></span>
            </div>
          </span>
        </div>
      </div>
    </>
  );
};

export { Preload };
