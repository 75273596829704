import React from 'react';
import { useIntl } from 'react-intl';
import { AsideMenuItem } from './AsideMenuItem';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { userHasRole } from '../../../helpers';

export function AsideMenuMain() {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;
  const intl = useIntl();

  return (
    <>
      {userHasRole(['ADMIN', 'ADMIN_COMPANY', 'RRHH', 'CDTO'], userRoles) && (
        <AsideMenuItem
          to="/dashboard"
          icon="/media/icons/duotune/general/gen001.svg"
          title={'Inicio'}
          fontIcon="bi-app-indicator"
        />
      )}
      {/* <AsideMenuItem
        to="/candidatos"
        icon="/media/icons/duotune/communication/com006.svg"
        title={'Candidatos'}
        fontIcon="bi-app-indicator"
      /> */}
      {userHasRole(['ADMIN', 'ADMIN_COMPANY', 'RRHH'], userRoles) && (
        <AsideMenuItem
          to="/cv/candidates"
          icon="/media/icons/duotune/files/fil003.svg"
          title={'Candidatos CV'}
          fontIcon="bi-app-indicator"
        />
      )}

      {userHasRole(['ADMIN'], userRoles) && (
        <div className="menu-item">
          <div className="menu-content pt-5 pb-2">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              Catálogos
            </span>
          </div>
        </div>
      )}
      {userHasRole(['ADMIN'], userRoles) && (
        <AsideMenuItemWithSub
          icon="/media/icons/duotune/general/gen054.svg"
          fontIcon="bi-layers"
          to="/catalogos/"
          title="Catálogos"
          hasBullet={false}
        >
          <AsideMenuItem
            to="/catalogos/prueba"
            icon="/media/icons/duotune/finance/fin010.svg"
            title="Prueba"
            fontIcon="bi-app-indicator"
          />
          <div className="menu-item">
            <div className="menu-content pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                Generales
              </span>
            </div>
          </div>
          <AsideMenuItem
            to="/catalogos/generico/TEST"
            title="Genérico"
            icon="/media/icons/duotune/abstract/abs009.svg"
          />
        </AsideMenuItemWithSub>
      )}
      {userHasRole(['ADMIN'], userRoles) && (
        <div className="menu-item">
          <div className="menu-content pt-5 pb-2">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              Administración
            </span>
          </div>
        </div>
      )}
      {userHasRole(['ADMIN'], userRoles) && (
        <>
          <AsideMenuItem
            to="/usuarios"
            title="Usuarios del sistema"
            icon="/media/icons/duotune/general/gen049.svg"
          />
          <AsideMenuItem
            to="/empresas"
            title="Empresas"
            icon="/media/icons/duotune/communication/com001.svg"
          />
          <AsideMenuItem
            to="/api_keys"
            title="API keys"
            icon="/media/icons/duotune/general/gen047.svg"
          />
        </>
      )}
    </>
  );
}
